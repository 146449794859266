import { TablePagination } from '@mui/material';
import React, { useState } from 'react';

function CustomTablePagination(props) {
  const { countlength, rows, pageProp, onPageChangeProp, onRowsPerPageChangeProp } = props;
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={countlength}
      rowsPerPage={rows}
      page={pageProp}
      onPageChange={onPageChangeProp}
      onRowsPerPageChange={onRowsPerPageChangeProp}
    />
  );
}

export default CustomTablePagination;
