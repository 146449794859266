import React, { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import { Link, useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CircularProgress, Grid } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Snackbar from '@mui/material/Snackbar';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withTranslation } from 'react-i18next';
import SearchField from '../../components/Common Components/SearchField';
import CmsBtn from '../../components/Common Components/Button';
import { getProblemList, createProblem } from '../../apis/problemApi';
import ProblemRow from './Row';

function Problem({ t }) {
  const [problem, setProblem] = useState([]);
  const alertMsg = sessionStorage.getItem('alertMsg');
  const [showImg, setShowImg] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [error, setErrors] = useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleClick = () => {
    setState({ ...state, open: true });
  };
  const openImage = () => {
    setShowImg(true);
  };
  const closeImage = () => {
    setShowImg(false);
  };
  const getProblem = async (useLoader) => {
    setLoader(true);
    try {
      const response = await getProblemList();
      if (response.data.statusCode === 200) {
        setProblem(response.data.data);
        setLoader(false);
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', t('timeout'));
        navigate('/login');
      }
      setLoader(false);
      setErrors(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    getProblem();
  }, []);

  useEffect(() => {
    if (alertMsg !== null && loader) {
      handleClick();
      setTimeout(() => {
        handleClose();
        sessionStorage.removeItem('alertMsg');
      }, 1500);
    }
  }, [loader]);

  const storeProblem = async (event) => {
    event.preventDefault();
    const problemName = event.target.name.value;
    if (problemName !== '') {
      try {
        const insertedResult = await createProblem({ name: problemName });
        if (insertedResult.data.statusCode === 200) {
          sessionStorage.setItem('alertMsg', t('created'));
          setShowImg(false);
          getProblem();
        }
      } catch (error) {
        if (error.statusCode === 401) {
          localStorage.clear();
          sessionStorage.setItem('alertMsg', t('timeout'));
          navigate('/login');
        }
        setShowImg(false);
        setLoader(false);
        setErrors(error.message);
        console.log(error);
      }
    }
  };
  return (
    <div>
      {!loader ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            icon={false}
            key={vertical + horizontal}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={
                alertMsg === t('updated') || alertMsg === t('created') ? t('success') : t('error')
              }
            >
              {`Your data is ${alertMsg} successfully`}
            </MuiAlert>
          </Snackbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <CmsBtn variant="contained" onClick={openImage}>
              {t('Add')}
            </CmsBtn>
          </Grid>
          <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Problem')}</TableCell>
                  <TableCell>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {problem.length > 0 ? (
                  problem.map((problem) => (
                    <ProblemRow key={problem.id} problem={problem} api={() => getProblem()} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                      {t('No Data Found')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={showImg} onClose={closeImage}>
            <DialogTitle>{t('Add')}</DialogTitle>
            <form onSubmit={storeProblem}>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={t('Problem Name')}
                  type="text"
                  fullWidth
                  variant="standard"
                  name="name"
                  required
                />
              </DialogContent>
              <DialogActions>
                <CmsBtn variant="contained" onClick={closeImage}>
                  {t('Cancel')}
                </CmsBtn>
                <CmsBtn variant="contained" type="submit">
                  {t('Save')}
                </CmsBtn>
              </DialogActions>
            </form>
          </Dialog>
        </>
      ) : (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
export default withTranslation()(Problem);
