// ----------------------------------------------------------------------
let userDetails = localStorage.getItem('userInfo');
if (userDetails) {
  userDetails = JSON.parse(userDetails);
}

const account = {
  displayName: userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : '-',
  email: 'demo@minimals.cc',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};

export default account;
