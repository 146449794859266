import React, { useState, useEffect, useRef } from 'react';
import { jsPDF } from 'jspdf';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import PublishIcon from '@mui/icons-material/Publish';
import MenuItem from '@mui/material/MenuItem';
import ErrorIcon from '@mui/icons-material/Error';
import AccordionActions from '@mui/material/AccordionActions';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Divider,
  Checkbox,
  DialogTitle,
  TextField
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
import { withTranslation } from 'react-i18next';
import image1 from '../../assets/1.png';
import image2 from '../../assets/2horizontal.png';
import image4 from '../../assets/3up.png';
import image6 from '../../assets/4.png';
import {
  getPatient,
  deletePatient,
  DeleteObservation,
  AddImageApi,
  OptImageApi,
  UploadImageApi
} from '../../apis/patientApi';
import { iosToDate, iosToDateValue } from '../../utils/formatTime';
import CmsBtn from '../../components/Common Components/Button';
import PatientImages from './PatientImages';
import { generatePDF } from './generatePDF';

const SelectFormate = [image1, image2, image4, image6];
const Accordion = styled((props) => <MuiAccordion elevation={5} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
);

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const PatientView = ({ t }) => {
  const [patient, setPatient] = useState();
  const [step, setStep] = useState(0);
  const [finalSelectedImages, setPatientImages] = useState([]);
  const customDate = iosToDateValue();
  const [selectDate, setSelectDate] = useState(customDate);
  const [popupOpen, setPopupOpen] = useState(false);
  const [showExportBtn, setShowExportBtn] = useState(false);
  const [imgPopup, setImgPopup] = useState('');
  const [showImg, setShowImg] = useState(false);
  const [perPageImage, setPerPageImage] = useState(0);
  const [notes, setNotes] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [showData, setShowData] = useState('Photos');
  const [showLoader, setShowLoader] = useState(false);
  const [observation, setObservation] = useState(false);
  const [observationDeleteId, setObservationDeleteId] = useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const [stateOb, setStateOb] = useState({
    openOb: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const openImage = () => {
    setShowImg(true);
  };
  const closeImage = () => {
    setShowImg(false);
    setSelectDate(customDate);
    setNotes('');
    setPerPageImage(0);
  };
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { id } = useParams();
  const galleryContainer = useRef();
  const uploadImage = useRef();
  const [imagesSelected, setImageSelected] = useState([]);
  const exportPdf = (save) => {
    setShowLoader(save);
    const arrImageUrls = [];
    imagesSelected.map((imgId) => {
      Object.keys(finalSelectedImages).map((objImg) => {
        finalSelectedImages[objImg].images.map(async (imgData) => {
          if (imgData.id.toString() === imgId.toString()) {
            arrImageUrls.push(imgData.url);
          }
          return true;
        });
        return true;
      });
      return true;
    });
    const OPT = showData === 'OPT' ? 'OPT' : null;
    generatePDF(
      arrImageUrls,
      perPageImage,
      patient,
      notes,
      selectDate,
      iosToDate,
      handleExportComplete,
      save,
      OPT,
      t
    );
  };
  const alertMsg = sessionStorage.getItem('alertMsg');
  const [error, setErrors] = useState('');
  const navigate = useNavigate();
  const startExport = () => {
    galleryContainer.current.classList.add('imageSelection');
    setStep(1);
  };
  const stopExport = () => {
    galleryContainer.current.classList.remove('imageSelection');
    setStep(0);
  };
  const getPatientView = async () => {
    try {
      const response = await getPatient(id);
      setShowLoading(true);
      if (response.data.statusCode === 200) {
        setPatient(response.data.data);
        setTimeout(() => {
          setShowLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setShowLoading(false);
      setErrors(error.message);
    }
  };
  const { vertical, horizontal, open } = state;
  const { openOb } = stateOb;
  useEffect(() => {
    getPatientView();
    setShowLoading(false);
  }, []);
  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };
  const handleChangeLoading = (status) => {
    setShowLoading(status);
  };
  const handleDelete = async () => {
    try {
      const result = await deletePatient(id);
      if (result.data.statusCode === 200) {
        sessionStorage.setItem('alertMsg', 'deleted');
        navigate('/patients', { replace: true });
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setPopupOpen(false);
      setErrors(error.message);
    }
  };

  const confirmConfig = () => {
    setStep(2);
    openImage();
  };

  if (state.open === true) {
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
  }
  const handleExportComplete = () => {
    setShowLoader(false);
    closeImage();
    setShowExportBtn(true);
  };
  const openObservadtion = (observationId) => {
    setObservationDeleteId(observationId);
    setObservation(true);
  };
  const ObservationDelete = async () => {
    const result = await DeleteObservation(observationDeleteId);
    if (result.status === 200) {
      setObservation(false);
      getPatientView();
      sessionStorage.setItem('alertMsg', 'deleted');
    }
  };
  useEffect(() => {
    if (alertMsg) {
      setStateOb({ ...stateOb, openOb: true });
      setTimeout(() => {
        setStateOb({ ...stateOb, openOb: false });
        sessionStorage.removeItem('alertMsg');
      }, 2000);
      getPatientView();
    }
  }, [alertMsg]);

  const TabChange = (data) => {
    setShowData(data);
    setImageSelected([]);
    setStep(0);
  };

  console.log(patient?.images, 'patient.images');

  const UploadImage = async (e) => {
    if (e.target.files.length !== true) {
      const formData = new FormData();
      Object.values(e.target.files).forEach((item) => formData.append('files', item));
      const opt = { isOpt: showData === 'OPT' ? 1 : 0 };
      try {
        const UploadImaga = await UploadImageApi(formData, opt);
        if (UploadImaga.status === 200) {
          if (showData === 'OPT') {
            const AddImageUrl = await OptImageApi(
              { imageUrls: UploadImaga.data.data.map((item) => item.url) },
              id
            );
            if (AddImageUrl.status === 200) {
              sessionStorage.setItem('alertMsg', t('updated'));
              getPatientView();
            }
          } else {
            const AddImageUrl = await AddImageApi(
              { imageUrls: UploadImaga.data.data.map((item) => item.url) },
              id
            );
            if (AddImageUrl.status === 200) {
              sessionStorage.setItem('alertMsg', t('updated'));
              getPatientView();
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      {patient && !showLoading ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            icon={false}
            key={vertical + horizontal}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {t('Your Pdf is downloaded')}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openOb}
            icon={false}
            key={vertical + horizontal}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={alertMsg === 'updated' ? 'success' : 'error'}
            >
              {`Your data is ${alertMsg} successfully`}
            </MuiAlert>
          </Snackbar>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid>
              <Link to="/patients/">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <Link to={`/patients/${id}/update`} state={patient}>
                <Button variant="contained">{t('Update')}</Button>
              </Link>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                style={{ backgroundColor: 'red' }}
                onClick={handleClickOpen}
              >
                {t('Delete')}
              </Button>
            </Grid>
          </Grid>
          <Dialog
            open={popupOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{ paddingBottom: '0px' }}>
              <Grid container justifyContent="center">
                <ErrorIcon fontSize="large" />
              </Grid>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4>{t('Are you sure to delete Patient?')}</h4>
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" autoFocus onClick={handleDelete}>
                    {t('Yes')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" onClick={handleClose}>
                    {t('No')}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          <Dialog
            open={observation}
            onClose={() => setObservation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{ paddingBottom: '0px' }}>
              <Grid container justifyContent="center">
                <ErrorIcon fontSize="large" />
              </Grid>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4>{t('Are you sure to delete Observation?')}</h4>
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" autoFocus onClick={ObservationDelete}>
                    {t('Yes')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" onClick={() => setObservation(false)}>
                    {t('No')}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          <Dialog open={showImg} style={{ overflow: 'hidden' }} onClose={closeImage}>
            {step === 0 ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    position: 'absolute',
                    right: '2%',
                    top: '2%'
                  }}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      background: '#0000002b',
                      borderRadius: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '30px',
                      height: '30px'
                    }}
                  >
                    <CloseIcon
                      style={{
                        color: 'white',
                        fontSize: '16px'
                      }}
                      onClick={() => closeImage()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <DialogTitle>
                <div className={step === 0 ? '' : 'TitleClass'}>
                  {imagesSelected.length !== 0 ? (
                    <div>
                      {step !== 0 ? (
                        <p>
                          {t('Total Selected Images')} : {imagesSelected.length}{' '}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                  {step === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <CloseIcon onClick={() => closeImage()} />
                    </div>
                  ) : (
                    <CloseIcon
                      onClick={() => closeImage()}
                      style={{
                        float: 'right',
                        cursor: 'pointer'
                      }}
                    />
                  )}
                </div>
              </DialogTitle>
            )}
            {step === 0 ? (
              <img
                src={imgPopup}
                style={{ objectFit: 'fill', overflow: 'hidden' }}
                width="100%"
                height="60%"
                alt="Patient"
              />
            ) : (
              <DialogContent>
                <div className="modalForm">
                  <Grid container style={{ marginBottom: '20px' }}>
                    <Grid item md={showData === 'OPT' ? 12 : 8}>
                      {showData !== 'OPT' ? (
                        <TextField
                          id="outlined-select-currency"
                          select
                          size="small"
                          fullWidth
                          required
                          value={perPageImage}
                          onChange={(e) => setPerPageImage(e.target.value)}
                          label={t('Select Per Page Image')}
                          style={{ marginBottom: '20px' }}
                          helperText=""
                        >
                          <MenuItem value="0" disabled={imagesSelected.length < 1}>
                            1
                          </MenuItem>
                          <MenuItem value="1" disabled={imagesSelected.length < 2}>
                            2
                          </MenuItem>
                          <MenuItem value="2" disabled={imagesSelected.length < 3}>
                            3
                          </MenuItem>
                          <MenuItem value="3" disabled={imagesSelected.length < 4}>
                            4
                          </MenuItem>
                        </TextField>
                      ) : null}
                      <TextField
                        size="small"
                        type="Date"
                        fullWidth
                        label={t('Image Date')}
                        value={selectDate}
                        error={selectDate.length <= 0}
                        required
                        style={{ marginBottom: '20px' }}
                        onChange={(e) => {
                          setSelectDate(e.target.value);
                        }}
                      />
                      <TextField
                        label={t('Notes')}
                        fullWidth
                        multiline
                        rows={6}
                        value={notes}
                        style={{ marginBottom: '20px' }}
                        inputProps={{
                          maxLength: 100
                        }}
                        onChange={(e) => setNotes(e.target.value)}
                        helperText={`${notes.length}/100`}
                      />
                    </Grid>
                    {showData !== 'OPT' ? (
                      <Grid item md={4} style={{ padding: '20px' }}>
                        <img
                          src={SelectFormate[perPageImage]}
                          alt="ImageFormat"
                          className="PdfImage"
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <DialogActions>
                    <CmsBtn
                      variant="contained"
                      onClick={() => exportPdf('save')}
                      required
                      disabled={selectDate.length <= 0 || showLoader === 'open'}
                      style={{ backgroundColor: '#2e7d32' }}
                    >
                      {showLoader === 'save' ? (
                        <CircularProgress
                          color="success"
                          style={{ height: '25px', width: '25px' }}
                        />
                      ) : (
                        t('Export')
                      )}
                    </CmsBtn>
                    <CmsBtn
                      variant="contained"
                      onClick={() => exportPdf('open')}
                      required
                      disabled={selectDate.length <= 0 || showLoader === 'save'}
                      style={{ backgroundColor: '#2e7d32' }}
                    >
                      {showLoader === 'open' ? (
                        <CircularProgress
                          color="success"
                          style={{ height: '25px', width: '25px' }}
                        />
                      ) : (
                        t('Open In Browser')
                      )}
                    </CmsBtn>

                    {/* <PDFDownloadLink document={<PdfCreate />} fileName="hello">
                      <Button>download</Button>
                    </PDFDownloadLink> */}
                  </DialogActions>
                </div>
              </DialogContent>
            )}
          </Dialog>
          <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
          <Grid container spacing={1} style={{ margin: '10px 0px' }}>
            <Grid item xs={2}>
              {t('First Name')}
            </Grid>
            <Grid item xs={10}>
              {patient.firstName === '' ? '-' : patient.firstName}
            </Grid>
            <Grid item xs={2}>
              {t('Last Name')}
            </Grid>
            <Grid item xs={10}>
              {patient.lastName === '' ? '-' : patient.lastName}
            </Grid>
            <Grid item xs={2}>
              {t('Date Of Birth')}
            </Grid>
            <Grid item xs={10}>
              {patient.dob !== null && patient.dob !== '' ? iosToDate(patient.dob) : '-'}
            </Grid>
          </Grid>
          <Divider>
            <div>
              <Button
                variant={showData === 'Photos' ? 'contained' : null}
                onClick={() => TabChange('Photos')}
                style={{
                  marginRight: '5px',
                  marginLeft: '5px',
                  backgroundColor: showData === 'Photos' ? '' : 'lightgray',
                  border: showData === 'Photos' ? '' : '1px solid grey'
                }}
              >
                {t('Photos')}
              </Button>
              {/* <Button
                variant={showData === 'Observation' ? 'contained' : null}
                onClick={() => TabChange('Observation')}
                style={{
                  marginRight: '5px',
                  marginLeft: '5px',
                  backgroundColor: showData === 'Observation' ? '' : 'lightgray',
                  border: showData === 'Observation' ? '' : '1px solid grey'
                }}
              >
                {t('Observation')}
              </Button> */}
              <Button
                variant={showData === 'OPT' ? 'contained' : null}
                onClick={() => TabChange('OPT')}
                style={{
                  marginRight: '5px',
                  marginLeft: '5px',
                  backgroundColor: showData === 'OPT' ? '' : 'lightgray',
                  border: showData === 'OPT' ? '' : '1px solid grey'
                }}
              >
                {t('OPT')}
              </Button>
            </div>
          </Divider>
          {showData === 'Photos' ? (
            <div className="galleryContainer" ref={galleryContainer}>
              {patient.images.length > 0 ? (
                <PatientImages
                  id="pdf"
                  images={patient?.images?.filter((item) => item.is_opt === false)}
                  openImage={openImage}
                  loading={handleChangeLoading}
                  setImageSelected={setImageSelected}
                  imagesSelected={imagesSelected}
                  setImgPopup={setImgPopup}
                  setPatientImages={setPatientImages}
                  api={getPatientView}
                />
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'grid',
                    alignItems: 'center',
                    minHeight: '400px'
                  }}
                >
                  <h3>{t('No Patient Images')}</h3>
                </div>
              )}
            </div>
          ) : null}
          {/* {showData === 'Observation' ? (
            <div className="galleryContainer" ref={galleryContainer}>
              {patient.patientObservations.length > 0 ? (
                <div style={{ marginTop: '10px' }}>
                  {patient.patientObservations.map((item, index) => (
                    <div style={{ display: 'flex', position: 'relative', columnGap: '20px' }}>
                      <Accordion
                        style={{ marginBottom: '10px', width: '100%', position: 'relative' }}
                        key={`${item.notes}${index} `}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                      >
                        <AccordionSummary>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              alignItems: 'center'
                            }}
                          >
                            <div>
                              <div>
                                {t('Added On')} : {iosToDate(item.observationDate)}
                              </div>
                              <div>
                                {t('Problems')} : {item.subObservations.length}
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        {item.subObservations.map((sub) => (
                          <AccordionDetails>
                            <Typography>
                              {t('notes')}: {sub.notes}
                            </Typography>
                            <Typography>
                              {t('problemId')}: {sub.problemId}
                            </Typography>
                          </AccordionDetails>
                        ))}
                      </Accordion>

                      <DeleteIcon
                        style={{
                          height: '75px',
                          color: 'red',
                          cursor: 'pointer'
                        }}
                        onClick={() => openObservadtion(item.observationId)}
                      />
                    </div>
                    // <Box
                    //   sx={{
                    //     backgroundColor: 'whitesmoke',
                    //     display: 'flex',
                    //     justifyContent: 'space-between',
                    //     alignItems: 'center',
                    //     padding: '10px 10px',
                    //     marginTop: '10px',
                    //     marginBottom: '10px'
                    //     // '&:hover': {
                    //     //   opacity: [0.9, 0.8, 0.7]
                    //     // }
                    //   }}
                    // >
                    //   <div>
                    //     <h3>Added On : {iosToDate(item.observationDate)}</h3>
                    //     <h3> Problems : {item.subObservations.length} </h3>
                    //   </div>
                    //   <div style={{ display: 'flex' }}>
                    //     <div>
                    //       <EditIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                    //     </div>
                    //     <div>
                    //       <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} />
                    //     </div>
                    //   </div>
                    // </Box>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'grid',
                    alignItems: 'center',
                    minHeight: '400px'
                  }}
                >
                  <h3>{t('No Patient Observations')}</h3>
                </div>
              )}
            </div>
          ) : null} */}
          {showData === 'OPT' ? (
            <div className="galleryContainer" ref={galleryContainer}>
              {patient.images.length > 0 ? (
                <PatientImages
                  id="pdf"
                  images={patient?.images?.filter((item) => item.is_opt === true)}
                  openImage={openImage}
                  loading={handleChangeLoading}
                  setImageSelected={setImageSelected}
                  imagesSelected={imagesSelected}
                  setImgPopup={setImgPopup}
                  setPatientImages={setPatientImages}
                  api={getPatientView}
                />
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'grid',
                    alignItems: 'center',
                    minHeight: '400px'
                  }}
                >
                  <h3>{t('No OPT Images')}</h3>
                </div>
              )}
            </div>
          ) : null}
          {patient.images.length > 0 ? (
            <div className="floatingdiv">
              {step === 0 ? (
                <>
                  <input
                    ref={uploadImage}
                    onChange={(e) => UploadImage(e)}
                    accept="image/*"
                    multiple
                    style={{ display: 'none' }}
                    type="file"
                  />
                  <CmsBtn
                    svg
                    style={{
                      marginRight: '20px',
                      display: showData === 'Observation' ? 'none' : ''
                    }}
                    startIcon={<PublishIcon />}
                    variant="contained"
                    onClick={() => uploadImage.current.click()}
                  >
                    {t('Upload')}
                  </CmsBtn>
                  {showData === 'Photos' ? (
                    <CmsBtn
                      style={{
                        display:
                          patient?.images?.filter((item) => item.is_opt === false).length === 0 ||
                          showData === 'Observation'
                            ? 'none'
                            : ''
                      }}
                      variant="contained"
                      onClick={startExport}
                    >
                      {t('Export')}
                    </CmsBtn>
                  ) : null}
                  {showData === 'OPT' ? (
                    <CmsBtn
                      style={{
                        display:
                          patient?.images?.filter((item) => item.is_opt === true).length === 0 ||
                          showData === 'Observation'
                            ? 'none'
                            : ''
                      }}
                      variant="contained"
                      onClick={startExport}
                    >
                      {t('Export')}
                    </CmsBtn>
                  ) : null}
                </>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                  <input
                    ref={uploadImage}
                    onChange={(e) => UploadImage(e)}
                    accept="image/*"
                    multiple
                    style={{ display: 'none' }}
                    type="file"
                  />
                  <CmsBtn
                    startIcon={<PublishIcon />}
                    svg
                    variant="contained"
                    onClick={() => uploadImage.current.click()}
                  >
                    {t('Upload')}
                  </CmsBtn>
                  <CmsBtn variant="contained">
                    {imagesSelected.length} {t('selected')}
                  </CmsBtn>
                  <CmsBtn
                    variant="contained"
                    style={{ backgroundColor: 'red' }}
                    onClick={stopExport}
                  >
                    {t('Cancel')}
                  </CmsBtn>
                  <CmsBtn
                    variant="contained"
                    onClick={confirmConfig}
                    disabled={imagesSelected.length <= 0}
                  >
                    {t('Next')}
                  </CmsBtn>
                </div>
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <input
                ref={uploadImage}
                onChange={(e) => UploadImage(e)}
                accept="image/*"
                multiple
                style={{ display: 'none' }}
                type="file"
              />
              <CmsBtn
                svg
                style={{ marginRight: '20px' }}
                variant="contained"
                startIcon={<PublishIcon />}
                onClick={() => uploadImage.current.click()}
              >
                {t('Upload')}
              </CmsBtn>
            </div>
          )}
        </>
      ) : (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(PatientView);
