import axiosInstance from '../config/api';

export const getStaffList = async () => {
  const response = await axiosInstance.get(`https://api.dentalcam.app/users/`);
  return response;
};

export const createStaff = async (formData) => {
  const response = await axiosInstance.post('https://api.dentalcam.app/users/create', formData);
  return response;
};

export const updateStaff = async (formData, id) => {
  const response = await axiosInstance.put(`https://api.dentalcam.app/users/${id}`, formData);
  return response;
};

export const deleteStaff = async (id) => {
  const response = await axiosInstance.delete(`https://api.dentalcam.app/users/${id}`);
  return response;
};

export const getStaff = async (id) => {
  const response = await axiosInstance.get(`https://api.dentalcam.app/users/${id}`);
  return response;
};
