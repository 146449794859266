export default function PrivacyPoliy() {
  return (
    <div style={{ padding: '10px', paddingBottom: '10%' }}>
      <u>
        <h1>Informativa sulla privacy</h1>
        <p>
          <strong>Ultimo aggiornamento</strong> [20 settembreth , 2022]
        </p>
        <p>
          La presente informativa viene resa in ossequio all&apos;art. 13 del Regolamento 2016/679
          (GDPR), ai sensi dell&apos;art. 13 del d.lgs. n. 196/2003 (Codice in materia di protezione
          dei dati personali) agli utenti che accedono al sito web
          <a href="https://dentalcam.app/" target="_blank" rel="noreferrer">
            <strong> www.dentalcam.app</strong>
          </a>
          ed &egrave; relativa a tutti i dati personali trattati secondo le modalit&agrave; di
          seguito indicate.
        </p>
        <p>
          La nostra Informativa sulla privacy fa parte e deve essere letta insieme ai Termini e
          condizioni del sito web e dell&apos;applicazione mobile. Ci riserviamo il diritto di
          modificare la presente Informativa sulla privacy in qualsiasi momento.
        </p>
        <p>
          Rispettiamo la privacy dei nostri utenti e di ogni persona che visita il nostro sito
          <a href="https://dentalcam.app/" target="_blank" rel="noreferrer">
            <strong> www.dentalcam.app</strong>
          </a>
          e l&apos;applicazione mobile &quot;<strong> DentalCam</strong>&quot;. Qui in
          <strong> Via Circonvallazione Sud 47, Gottolengo (BS)</strong> cui ci si riferisce come
          (&quot;noi&quot;, &quot;ci&quot;, o &quot;nostro&quot;), ci impegniamo a proteggere le
          vostre informazioni personali e il vostro diritto alla privacy ai sensi della presente
          politica sulla privacy. In caso di domande o dubbi sulla nostra politica o sulle nostre
          pratiche relative ai vostri dati personali, vi preghiamo di contattarci all&apos;indirizzo
          <a target="_blank" rel="noreferrer" href="mailto:info@dentalcam.app">
            <strong> info@dentalcam.app</strong>
          </a>
          .
        </p>
        <p>
          Quando visitate il nostro sito web
          <a href="https://dentalcam.app/" target="_blank" rel="noreferrer">
            <strong> www.dentalcam.app</strong>
          </a>
          (&quot;Sito&quot;) e l&apos;applicazione mobile
          <strong> (&quot;DentalCam&quot;)</strong>e utilizzate i nostri servizi, ci affidate i
          vostri dati personali. Prendiamo molto sul serio la vostra privacy. In questa informativa
          sulla privacy, descriviamo la nostra politica sulla privacy. Cerchiamo di spiegarvi nel
          modo pi&ugrave; chiaro possibile quali informazioni raccogliamo, come le utilizziamo e
          quali diritti avete in merito. Ci auguriamo che l&apos;utente dedichi un po&apos; di tempo
          a leggerla con attenzione, perch&eacute; &egrave; importante. Se non siete d&apos;accordo
          con i termini della presente informativa sulla privacy, siete pregati di interrompere
          l&apos;utilizzo del nostro sito e dei nostri servizi.
        </p>
        <p>
          La presente informativa sulla privacy si applica a tutte le informazioni raccolte
          attraverso il nostro sito web e la nostra applicazione mobile, e/o qualsiasi servizio,
          vendita, marketing o evento correlato (in questa informativa sulla privacy ci riferiamo ad
          essi collettivamente come
          <strong> &quot;sito web e applicazione mobile&quot;). &nbsp;</strong>
        </p>
        <h1>SU DI NOI</h1>
        <p>
          L&apos;app e il sito web DentalCam offrono una piattaforma attentamente progettata per i
          servizi odontoiatrici. Questa piattaforma fornisce servizi alla clinica ortodontica.
        </p>
        <p>Siamo in Italia.</p>
        <h2>
          Vi invitiamo a leggere attentamente la presente informativa sulla privacy, che vi
          aiuter&agrave; a prendere decisioni informate sulla condivisione dei vostri dati personali
          con noi. &nbsp;
        </h2>
        <h1>
          <ul>
            <li>QUALI INFORMAZIONI RACCOGLIAMO?</li>
          </ul>
        </h1>
        <p>Le informazioni personali che ci vengono comunicate</p>
        <p>
          Raccogliamo i dati personali che l&apos;utente ci fornisce volontariamente quando esprime
          il proprio interesse a ottenere informazioni su di noi o sui nostri servizi, partecipando
          alle attivit&agrave; del sito web e dell&apos;applicazione mobile o contattandoci in altro
          modo.
        </p>
        <p>
          Le informazioni personali che raccogliamo dipendono dal contesto delle vostre interazioni
          con noi e con il sito web e l&apos;applicazione mobile, dalle scelte che fate e dalle
          funzioni che utilizzate. Le informazioni personali che raccogliamo possono includere
          quanto segue:
        </p>
        <p>
          <strong> Nome e dati di contatto.</strong> Raccogliamo nome, cognome, data di nascita,
          ragione sociale, username, l&apos;indirizzo e-mail, il numero di telefono, password, i
          dati medici e altri dati simili.
        </p>
        <p>
          <strong>Accesso.</strong> Una volta che l&apos;utente lo consente attraverso l&apos;app,
          accediamo a quanto segue:
        </p>
        <ul>
          <li>Accesso a Internet</li>
          <li>Accesso ai servizi di base</li>
        </ul>
        <p>
          <strong>Credenziali.</strong> Raccogliamo password, suggerimenti di password e
          informazioni di sicurezza simili utilizzate per l&apos;autenticazione e l&apos;accesso
          all&apos;account.
        </p>
        <h1>Informazioni raccolte automaticamente</h1>
        <p>
          Raccogliamo automaticamente alcune informazioni quando visitate, utilizzate o navigate sul
          Sito web e sull&apos;Applicazione mobile. Queste informazioni non rivelano la vostra
          identit&agrave; specifica (come il vostro nome o le informazioni di contatto), ma possono
          includere informazioni sul dispositivo e sull&apos;utilizzo, come l&apos;indirizzo IP, il
          browser e le caratteristiche del dispositivo, il sistema operativo, le preferenze
          linguistiche, gli URL di riferimento, il nome del dispositivo, il paese, la posizione, le
          informazioni su come e quando utilizzate il nostro sito web e l&apos;applicazione mobile e
          altre informazioni tecniche. &nbsp;Se accedete al nostro sito con il vostro dispositivo
          mobile, possiamo raccogliere automaticamente informazioni sul dispositivo (come l&apos;ID
          del dispositivo mobile, il modello e il produttore), il sistema operativo, le informazioni
          sulla versione e l&apos;indirizzo IP. Queste informazioni sono necessarie principalmente
          per mantenere la sicurezza e il funzionamento del nostro sito web e dell&apos;applicazione
          mobile, nonch&eacute; per i nostri scopi di analisi e reporting interni.
        </p>
        <p>
          Come molte aziende, anche noi raccogliamo informazioni attraverso i cookie e tecnologie
          simili. Per saperne di pi&ugrave;, consultare la nostra Politica sui cookie.
        </p>
        <h1>Informazioni raccolte da altre fonti</h1>
        <p>
          Possiamo ottenere informazioni sull&apos;utente da altre fonti, come database pubblici,
          partner di marketing congiunti, piattaforme di social media (come Facebook), nonch&eacute;
          da altre terze parti.&nbsp;
        </p>
        <p>
          Se avete scelto di iscrivervi alla nostra newsletter, il vostro nome, cognome e indirizzo
          e-mail saranno condivisi con il nostro fornitore di newsletter. Questo per tenervi
          aggiornati su informazioni e offerte a scopo di marketing.
        </p>
        <h1>
          <ul>
            <li>COME UTILIZZIAMO LE VOSTRE INFORMAZIONI?</li>
          </ul>
        </h1>
        <p>
          Utilizziamo i vostri dati personali per questi scopi in base ai nostri legittimi interessi
          commerciali (&quot;Scopi commerciali&quot;), per stipulare o eseguire un contratto con voi
          (&quot;Contrattuale&quot;), con il vostro consenso (&quot;Consenso&quot;) e/o per
          adempiere ai nostri obblighi legali (&quot;Motivi legali&quot;). Indichiamo i motivi
          specifici di trattamento su cui ci basiamo accanto a ciascuna finalit&agrave; elencata di
          seguito. &nbsp;
        </p>
        <p>Utilizziamo le informazioni raccolte o ricevute: &nbsp;</p>
        <ul>
          <li>
            <strong>fornire pubblicit&agrave; mirata all&apos;utente</strong> per i nostri scopi
            commerciali e/o con il suo consenso. Possiamo utilizzare le informazioni
            dell&apos;utente per sviluppare e visualizzare contenuti e pubblicit&agrave; (e
            collaborare con terze parti che lo fanno) personalizzati in base ai suoi interessi e/o
            alla sua posizione e per misurarne l&apos;efficacia. [Per ulteriori informazioni,
            consultare la nostra Politica sui cookie.
          </li>

          <li>
            <strong> Richiesta di feedback</strong> per i nostri scopi commerciali e/o con il vostro
            consenso. Potremmo utilizzare le informazioni dell&apos;utente per richiedere un
            feedback e per contattarlo in merito all&apos;utilizzo del nostro sito web e
            dell&apos;applicazione mobile.
          </li>

          <li>
            <strong>Per proteggere il nostro sito web e l&apos;applicazione mobile</strong>
            per scopi commerciali e/o motivi legali. &nbsp;Possiamo utilizzare le vostre
            informazioni nell&apos;ambito dei nostri sforzi per mantenere il nostro sito web e
            l&apos;applicazione mobile sicuri e protetti (ad esempio, per il monitoraggio e la
            prevenzione delle frodi).
          </li>
          <li>
            <strong>Per applicare i nostri termini, condizioni e politiche</strong>
            per i nostri scopi commerciali e come richiesto dalla legge.
          </li>
          <li>
            <strong> Per rispondere a richieste legali e prevenire danni</strong>, come richiesto
            dalla legge. Se riceviamo una citazione in giudizio o un&apos;altra richiesta legale,
            potremmo aver bisogno di ispezionare i dati in nostro possesso per determinare come
            rispondere.
          </li>
          <li>
            <strong> Per altri scopi commerciali.</strong> Possiamo utilizzare le vostre
            informazioni per altri scopi commerciali, come l&apos;analisi dei dati,
            l&apos;identificazione delle tendenze di utilizzo, la determinazione dell&apos;efficacia
            delle nostre campagne promozionali e la valutazione e il miglioramento del nostro sito
            web e dell&apos;applicazione mobile, dei prodotti, dei servizi, del marketing e della
            vostra esperienza.
          </li>
        </ul>

        <h1>
          <ul>
            <li>LE VOSTRE INFORMAZIONI SARANNO CONDIVISE CON QUALCUNO?</li>
          </ul>
        </h1>
        <p>Condividiamo e divulghiamo le vostre informazioni solo nelle seguenti situazioni:</p>
        <ul>
          <li>
            <strong> Conformit&agrave; alle leggi. </strong>Potremmo divulgare le informazioni
            dell&apos;utente qualora fossimo obbligati per legge a farlo per ottemperare alle leggi
            vigenti, alle richieste governative, a un procedimento giudiziario, a un ordine del
            tribunale o a procedimenti legali, ad esempio in risposta a un ordine del tribunale o a
            una citazione in giudizio (anche in risposta alle autorit&agrave; pubbliche per
            soddisfare i requisiti di sicurezza nazionale o di applicazione della legge).
          </li>
          <li>
            <strong> Interessi vitali e diritti legali.</strong> Possiamo divulgare le vostre
            informazioni qualora riteniamo che sia necessario indagare, prevenire o prendere
            provvedimenti in merito a potenziali violazioni delle nostre politiche, sospette frodi,
            situazioni che comportano potenziali minacce alla sicurezza di qualsiasi persona e
            attivit&agrave; illegali, o come prova in controversie in cui siamo coinvolti.
          </li>
          <li>
            <strong>Fornitori, consulenti e altri fornitori di servizi terzi.</strong>
            Possiamo condividere i vostri dati con venditori, fornitori di servizi, appaltatori o
            agenti terzi che svolgono servizi per noi o per nostro conto e che richiedono
            l&apos;accesso a tali informazioni per svolgere il loro lavoro.&nbsp;
          </li>
          <li>
            <strong> Trasferimenti di attivit&agrave;. </strong>Potremmo condividere o trasferire le
            vostre informazioni in relazione a, o durante le trattative di, qualsiasi fusione,
            vendita di beni aziendali, finanziamento o acquisizione di tutta o parte della nostra
            attivit&agrave; a un&apos;altra societ&agrave;.
          </li>
          <li>
            <strong> Inserzionisti terzi.</strong> Possiamo utilizzare societ&agrave; pubblicitarie
            terze per pubblicare annunci quando visitate il Sito web e l&apos;Applicazione mobile.
            Queste aziende possono utilizzare le informazioni sulle visite al nostro sito web,
            all&apos;applicazione mobile e ad altri siti web, contenute nei cookie web e in altre
            tecnologie di tracciamento, per fornire annunci pubblicitari su beni e servizi di vostro
            interesse.&nbsp;
          </li>
          <li>
            <strong> Affiliati.</strong> Possiamo condividere le informazioni dell&apos;utente con
            le nostre affiliate, nel qual caso richiederemo a tali affiliate di rispettare la
            presente informativa sulla privacy. Le societ&agrave; affiliate comprendono la nostra
            societ&agrave; madre e tutte le filiali, i partner di joint venture o altre
            societ&agrave; che controlliamo o che sono sotto controllo comune con noi.
          </li>
          <li>
            <strong> Partner commerciali.</strong> Possiamo condividere le vostre informazioni con i
            nostri partner commerciali per offrirvi determinati prodotti, servizi o promozioni.
          </li>
          <li>
            <strong> Con il vostro consenso.</strong> Possiamo divulgare i vostri dati personali per
            qualsiasi altro scopo con il vostro consenso.
          </li>
          <li>
            <strong> Altri utenti. </strong>Quando l&apos;utente condivide informazioni personali
            (ad esempio, pubblicando commenti, contributi o altri contenuti sul Sito web e
            sull&apos;Applicazione mobile) o interagisce in altro modo con le aree pubbliche del
            Sito web e dell&apos;Applicazione mobile, tali informazioni personali possono essere
            visualizzate da tutti gli utenti e possono essere distribuite pubblicamente al di fuori
            del Sito web e dell&apos;Applicazione mobile in perpetuo.
          </li>
        </ul>
        <h1>
          <ul>
            <li>UTILIZZIAMO COOKIE E ALTRE TECNOLOGIE DI TRACCIAMENTO?</li>
          </ul>
        </h1>
        <p>
          Possiamo utilizzare cookie e tecnologie di tracciamento simili (come web beacon e pixel)
          per accedere o memorizzare informazioni. Informazioni specifiche sull&apos;utilizzo di
          tali tecnologie e sulle modalit&agrave; di rifiuto di alcuni cookie sono contenute nella
          nostra Politica sui cookie.
        </p>
        <h1>
          <ul>
            <li>LE VOSTRE INFORMAZIONI VENGONO TRASFERITE A LIVELLO INTERNAZIONALE?</li>
          </ul>
        </h1>
        <p>
          Il server della nostra piattaforma si trova in Germania. Le informazioni raccolte
          dall&apos;utente possono essere archiviate ed elaborate a livello globale in vari paesi in
          cui la nostra Societ&agrave; o i nostri agenti o appaltatori dispongono di strutture;
          accedendo ai nostri siti e utilizzando i nostri servizi, l&apos;utente acconsente a tale
          trasferimento di informazioni al di fuori del proprio paese.&nbsp;
        </p>
        <p>
          Tali paesi possono avere leggi diverse, e potenzialmente non altrettanto protettive,
          rispetto a quelle del vostro paese. Ogni volta che condividiamo dati personali provenienti
          dallo Spazio economico europeo, ci affidiamo a misure legali per il trasferimento di tali
          dati, come il Privacy Shield o le clausole contrattuali standard dell&apos;UE. Se
          risiedete nel SEE o in altre regioni con leggi che regolano la raccolta e l&apos;utilizzo
          dei dati, vi preghiamo di notare che acconsentite al trasferimento dei vostri dati
          personali nei paesi in cui operiamo. Fornendo i vostri dati personali, acconsentite a
          qualsiasi trasferimento ed elaborazione in conformit&agrave; con la presente Politica. Non
          trasferiremo i vostri dati personali a un destinatario estero.
        </p>
        <h1>
          <ul>
            <li>PER QUANTO TEMPO CONSERVIAMO LE VOSTRE INFORMAZIONI?</li>
          </ul>
        </h1>
        <p>
          Conserveremo le informazioni personali dell&apos;utente solo per il tempo necessario agli
          scopi indicati nella presente informativa sulla privacy, a meno che un periodo di
          conservazione pi&ugrave; lungo non sia richiesto o consentito dalla legge (ad esempio per
          esigenze fiscali, contabili o altri requisiti legali). Quando non avremo pi&ugrave; la
          legittima necessit&agrave; di trattare i vostri dati personali, li cancelleremo o li
          renderemo anonimi oppure, se ci&ograve; non fosse possibile (ad esempio perch&eacute; i
          vostri dati personali sono stati archiviati in archivi di backup), li conserveremo in modo
          sicuro e li isoleremo da qualsiasi ulteriore trattamento fino a quando non sar&agrave;
          possibile cancellarli.
        </p>
        <h1>
          <ul>
            <li>COME MANTENIAMO AL SICURO LE VOSTRE INFORMAZIONI?</li>
          </ul>
        </h1>
        <p>
          Abbiamo implementato misure di sicurezza tecniche e organizzative adeguate per proteggere
          la sicurezza delle informazioni personali che trattiamo. Tuttavia, si ricorda che non
          possiamo garantire che Internet sia sicuro al 100%. Anche se faremo del nostro meglio per
          proteggere le vostre informazioni personali, la trasmissione di informazioni personali da
          e verso il nostro Sito web e l&apos;Applicazione mobile &egrave; a vostro rischio e
          pericolo. L&apos;utente dovrebbe accedere ai servizi solo in un ambiente sicuro.
        </p>
        <h1>
          <ul>
            <li>RACCOGLIAMO INFORMAZIONI DA MINORI?</li>
          </ul>
        </h1>
        <p>
          Non sollecitiamo consapevolmente dati da o commercializziamo a bambini di et&agrave;
          inferiore ai 16 anni. &nbsp;Utilizzando il Sito web e l&apos;Applicazione mobile,
          l&apos;utente dichiara di avere almeno 16 anni o di essere il genitore o il tutore di un
          minore e di acconsentire all&apos;utilizzo del Sito web e dell&apos;Applicazione mobile da
          parte di tale minore. &nbsp;Se veniamo a conoscenza della raccolta di dati personali di
          utenti di et&agrave; inferiore ai 16 anni, disattiveremo l&apos;account e adotteremo
          misure ragionevoli per cancellare prontamente tali dati dai nostri archivi. &nbsp;Se si
          viene a conoscenza di dati raccolti da bambini di et&agrave; inferiore ai 16 anni, si
          prega di contattarci all&apos;indirizzo
          <a target="_blank" rel="noreferrer" href="mailto:info@dentalcam.app">
            <strong> info@dentalcam.app</strong>
          </a>
          .
        </p>
        <h1>
          <ul>
            <li>QUALI SONO I VOSTRI DIRITTI ALLA PRIVACY?</li>
          </ul>
        </h1>
        <p>. Informazioni personali</p>
        <p>
          L&apos;utente pu&ograve;, in qualsiasi momento, rivedere o modificare le informazioni
          contenute nel proprio account o chiudere l&apos;account stesso:
        </p>

        <ul>
          <li>Contattateci utilizzando le informazioni di contatto fornite di seguito</li>
        </ul>

        <p>
          Su richiesta dell&apos;utente di terminare il proprio account, provvederemo a disattivare
          o eliminare l&apos;account e le informazioni dell&apos;utente dai nostri database attivi.
          Tuttavia, alcune informazioni potrebbero essere conservate nei nostri archivi per
          prevenire frodi, risolvere problemi, assistere in eventuali indagini, applicare le nostre
          Condizioni d&apos;uso e/o rispettare i requisiti legali.
        </p>
        <p>
          <strong> Cookie e tecnologie simili:</strong> La maggior parte dei browser Web &egrave;
          impostata per accettare i cookie per impostazione predefinita. Se si preferisce, di solito
          si pu&ograve; scegliere di impostare il browser in modo da rimuovere i cookie e
          rifiutarli. Se si sceglie di rimuovere i cookie o di rifiutarli, ci&ograve; potrebbe
          influire su alcune funzioni o servizi del nostro Sito web e dell&apos;Applicazione
          mobile.&nbsp;
        </p>
        <h1>
          <ul>
            <li>AGGIORNIAMO QUESTA POLITICA?</li>
          </ul>
        </h1>
        <p>
          La presente informativa sulla privacy pu&ograve; essere aggiornata di tanto in tanto. La
          versione aggiornata sar&agrave; indicata da una data aggiornata &quot;Revised&quot; e la
          versione aggiornata entrer&agrave; in vigore non appena sar&agrave; accessibile. Qualora
          dovessimo apportare modifiche sostanziali alla presente informativa sulla privacy,
          potremmo informare l&apos;utente pubblicando in modo visibile un avviso di tali modifiche
          o inviando direttamente una notifica. Vi invitiamo a consultare frequentemente la presente
          informativa sulla privacy per essere informati su come stiamo proteggendo le vostre
          informazioni.
        </p>
        <h1>
          <ul>
            <li>COME POTETE CONTATTARCI IN MERITO A QUESTA POLITICA?</li>
          </ul>
        </h1>
        <p>
          In caso di domande o commenti su questa politica, &egrave; possibile inviare
          un&apos;e-mail all&apos;indirizzo
          <a target="_blank" rel="noreferrer" href="mailto:info@dentalcam.app">
            <strong>info@dentalcam.app</strong>
          </a>
          o compilare il modulo di contatto disponibile sul sito Web.&nbsp;
        </p>
      </u>
    </div>
  );
}
