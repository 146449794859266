import { Navigate } from 'react-router-dom';

const Logout = () => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user_role');
  localStorage.removeItem('plan');
  return <Navigate to="/login" />;
};
export default Logout;
