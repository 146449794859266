import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { format } from 'date-fns';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CollectionsIcon from '@mui/icons-material/Collections';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { withTranslation } from 'react-i18next';
import { iosToDate } from '../../utils/formatTime';
import { deletePatient } from '../../apis/patientApi';

function PatientRow({ patient, api, t }) {
  const navigate = useNavigate();
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setErrors] = useState('');
  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };
  const handleDelete = async () => {
    try {
      const result = await deletePatient(patient.id);
      if (result.data.statusCode === 200) {
        sessionStorage.setItem('alertMsg', 'deleted');
        api();
        setPopupOpen(false);
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setPopupOpen(false);
      setErrors(error.message);
    }
  };
  return (
    <TableRow
      key={patient.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      style={{ cursor: 'unset' }}
      // onClick={(event) => navigate(`/patients/${patient.id}`)}
    >
      <Dialog
        open={popupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ paddingBottom: '0px' }}>
          <Grid container justifyContent="center">
            <ErrorIcon fontSize="large" />
          </Grid>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4>{t('Are you sure to delete Patient?')}</h4>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" autoFocus onClick={handleDelete}>
                {t('Yes')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" onClick={handleClose}>
                {t('No')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <TableCell
        className="tableCellHover"
        onClick={() => navigate(`/patients/${patient.id}`)}
        style={{
          maxWidth: 70,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        component="th"
        scope="row"
      >
        {patient.firstName}
      </TableCell>
      <TableCell
        className="tableCellHover"
        onClick={() => navigate(`/patients/${patient.id}`)}
        style={{
          maxWidth: 70,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {patient.lastName ? patient.lastName : '-'}
      </TableCell>
      <TableCell
        className="tableCellHover"
        onClick={() => navigate(`/patients/${patient.id}`)}
        // style={{ textAlign: `${patient.dob !== null && patient.dob !== '' ? '' : 'center'}` }}
      >
        {patient.dob !== null && patient.dob !== '' ? iosToDate(patient.dob) : '-'}
      </TableCell>
      <TableCell>
        <Link to={`/patients/${patient.id}`}>
          <CollectionsIcon />
        </Link>
        <Link to={`/patients/${patient.id}/update`} state={patient}>
          <EditIcon />
        </Link>
        <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={handleClickOpen} />
      </TableCell>
    </TableRow>
  );
}

export default withTranslation()(PatientRow);
