import * as React from 'react';
import { useParams, Link, useHistory, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import ErrorIcon from '@mui/icons-material/Error';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getClinic, deleteClinic } from '../../apis/clinicApis';
import { iosToDate } from '../../utils/formatTime';
import PageWrapper from '../../components/Common Components/PageStyle';

const ClinicsDetail = ({ t }) => {
  const [clinicData, setClinicData] = useState();
  const { id } = useParams();
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setErrors] = useState('');
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };

  const handleDelete = async () => {
    try {
      const result = await deleteClinic(id);
      if (result.data.statusCode === 200) {
        sessionStorage.setItem('alertMsg', t('deleted'));
        navigate('/clinics', { replace: true });
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', t('timeout'));
        navigate('/login');
      }
      setErrors(error.message);
      setPopupOpen(false);
    }
  };
  const getClinicData = async () => {
    try {
      const response = await getClinic(id);
      if (response.data.statusCode === 200) {
        setClinicData(response.data.data);
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', t('timeout'));
        navigate('/login');
      }
      setErrors(error.message);
      console.log(error);
    }
  };
  useEffect(() => getClinicData(), [id]);
  return (
    <div fixed maxWidth="md">
      {clinicData ? (
        <Grid container alignItems="flex-start" direction="row" md={12}>
          <Grid item xs={11}>
            <PageWrapper>
              <Grid container justifyContent="center" alignItems="baseline" md={12}>
                <IconButton
                  color="primary"
                  onClick={() => navigate(-1)}
                  aria-label={t('upload picture')}
                  component="span"
                >
                  <ArrowBackIcon />
                </IconButton>
                <Grid item md={6} lg={6} xl={6}>
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <h1>{t('View Clinic')}</h1>
                  </div>
                  <Card>
                    <CardContent>
                      <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t('Clinic Name')}</TableCell>
                            <TableCell style={{ textTransform: 'capitalize' }}>
                              {clinicData.clinicName === '' ? '-' : clinicData.clinicName}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('User name')}</TableCell>
                            <TableCell style={{ textTransform: 'capitalize' }}>
                              {clinicData.clinicUsername === '' ? '-' : clinicData.clinicUsername}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('Email')}</TableCell>
                            <TableCell>
                              {clinicData.clinicEmail === '' ? '-' : clinicData.clinicEmail}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('Plan name')}</TableCell>
                            <TableCell>
                              {clinicData.planName === '' ? '-' : clinicData.planName}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('Active')}</TableCell>
                            <TableCell>{clinicData.is_active ? 'Active' : 'In Active'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('Expiry Date')}</TableCell>
                            <TableCell>
                              {clinicData.expiry_date === ''
                                ? '-'
                                : iosToDate(clinicData.expiryDate)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.5fr 0.5fr',
                          columnGap: '15px'
                        }}
                      >
                        <Button
                          onClick={() => navigate(`/clinics/${id}/update`)}
                          style={{ width: '100%' }}
                          variant="contained"
                        >
                          {t('Update')}
                        </Button>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: 'red', width: '100%' }}
                          onClick={handleClickOpen}
                        >
                          {t('Delete')}
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </PageWrapper>
          </Grid>
          <Dialog
            open={popupOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{ paddingBottom: '0px' }}>
              <Grid container justifyContent="center">
                <ErrorIcon fontSize="large" />
              </Grid>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4>{t('Are you sure to delete Clinic?')}</h4>
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" autoFocus onClick={handleDelete}>
                    {t('Yes')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" onClick={handleClose}>
                    {t('No')}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          {/* <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">First Name</TableCell>
                  <TableCell align="left">
                    {clinicData.firstName === '' ? '-' : clinicData.firstName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Last Name</TableCell>
                  <TableCell align="left">
                    {clinicData.lastName === '' ? '-' : clinicData.lastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">User Name</TableCell>
                  <TableCell align="left">
                    {clinicData.userName === '' ? '-' : clinicData.userName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Expiry Date</TableCell>
                  <TableCell align="left">
                    {clinicData.expiryDate === '' ? '-' : iosToDate(clinicData.expiryDate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Active</TableCell>
                  <TableCell align="left">
                    {clinicData.isActive ? 'Active' : 'Not Active'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Password</TableCell>
                  <TableCell align="center">{clinicData.password}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
        </Grid>
      ) : (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(ClinicsDetail);
