import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { Link, useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CircularProgress, Grid } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Snackbar from '@mui/material/Snackbar';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { withTranslation } from 'react-i18next';
import SearchField from '../../components/Common Components/SearchField';
import CmsBtn from '../../components/Common Components/Button';
import { getPatientList } from '../../apis/patientApi';
import PatientRow from './Row';
import CustomTablePagination from '../../components/Common Components/CustomPagination';

function Patient({ t }) {
  const [patient, setPatient] = useState([]);
  const alertMsg = sessionStorage.getItem('alertMsg');
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [checkFirst, setCheckFirst] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showPage, setShowPage] = useState();
  const [search, setSearch] = useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const [pageDetails, setPageDetails] = useState({
    pageStart: 1,
    pageEnd: 10
  });
  const [pageination, setPageination] = useState('forward');
  const [error, setErrors] = useState('');
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleClick = () => {
    setState({ ...state, open: true });
  };
  const getPatient = async (pageSize, page, search, useLoader) => {
    if (useLoader) {
      setLoader(true);
    }
    try {
      const response = await getPatientList(pageSize, page, search);
      if (response.data.statusCode === 200) {
        setPatient(response.data.data.records);
        setShowPage(response.data.data.totalRecords);
        if (pageination === 'forward') {
          if (page > 1) {
            const pageStart = pageDetails.pageEnd + 1;
            let pageEnd = pageStart + 9;
            pageEnd = pageEnd > showPage ? showPage : pageEnd;
            setPageDetails({
              pageStart,
              pageEnd
            });
          } else {
            let pageEnd = 10;
            pageEnd = pageEnd > showPage ? showPage : pageEnd;
            setPageDetails({
              pageStart: 1,
              pageEnd
            });
          }
        } else {
          const pageStart = pageDetails.pageStart - 10;
          // let pageEnd = pageDetails.pageEnd - 10;
          let pageEnd = pageStart + 9;
          pageEnd = pageEnd > showPage ? showPage : pageEnd;
          setPageDetails({
            pageStart,
            pageEnd
          });
        }
        setLoader(false);
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', t('timeout'));
        navigate('/login');
      }
      setLoader(false);
      setErrors(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    if (alertMsg) {
      handleClick();
      setTimeout(() => {
        handleClose();
        sessionStorage.removeItem('alertMsg');
      }, 1500);
    }
    getPatient(pageSize, page, search, true);
  }, [alertMsg]);

  useEffect(() => {
    if (checkFirst) {
      setCheckFirst(false);
    } else {
      getPatient(pageSize, page, search, false);
    }
  }, [pageSize, page, search]);

  const searchData = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const nextPage = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      setPageination('forward');
      setPage(page + 1);
    }, 1000);
  };

  const backPage = () => {
    setLoader(true);
    setTimeout(() => {
      if (page > 1) {
        setPage(page - 1);
      }
      setLoader(false);
      setPageination('backward');
    }, 1000);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };
  const SortingData = () => {
    setPatient([...patient].reverse());
  };

  return (
    <div>
      {!loader ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            icon={false}
            key={vertical + horizontal}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={
                alertMsg === t('updated') || alertMsg === t('created') ? t('success') : t('error')
              }
            >
              {`Your data is ${alertMsg} successfully`}
            </MuiAlert>
          </Snackbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <SearchField searchData={searchData} search={search} />
            <Link to="/patients/create">
              <CmsBtn variant="contained">{t('Create')}</CmsBtn>
            </Link>
          </Grid>
          <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell onClick={SortingData}>{t('First Name')}</TableCell>
                  <TableCell>{t('Last Name')}</TableCell>
                  <TableCell>{t('Date Of Birth')}</TableCell>
                  <TableCell>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patient.length > 0 ? (
                  patient.map((patient) => (
                    <PatientRow
                      key={patient.id}
                      api={() => getPatient(pageSize, page, search)}
                      patient={patient}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                      {t('No Data Found')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <CustomTablePagination
            component="div"
            pageProp={page}
            rows={pageSize}
            countlength={patient.length}
            onPageChangeProp={handleChangePage}
            onRowsPerPageChangeProp={handleChangeRowsPerPage}
          /> */}
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <h5>
              {pageDetails.pageStart} -
              {pageDetails.pageEnd >= showPage ? showPage : pageDetails.pageEnd} of {showPage}
            </h5>
            <Grid>
              <IconButton
                onClick={backPage}
                disabled={page === 1 || loader}
                size="small"
                style={{ color: `${page === 1 ? 'grey' : 'blue'}` }}
                aria-label="Back"
                component="span"
              >
                <ArrowBackIosNewIcon
                  style={{ color: `${page === 1 ? 'grey' : 'blue'}` }}
                  fontSize="8px"
                />
              </IconButton>

              <IconButton
                disabled={pageDetails.pageEnd >= showPage || loader}
                onClick={nextPage}
                size="small"
                style={{ color: `${pageDetails.pageEnd >= showPage ? 'grey' : 'blue'}` }}
                aria-label="Next"
                component="span"
              >
                <ArrowForwardIosIcon
                  style={{ color: `${pageDetails.pageEnd >= showPage ? 'grey' : 'blue'}` }}
                  fontSize="8px"
                />
              </IconButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
export default withTranslation()(Patient);
