import React from 'react';
import PatientForm from './PatientForm';

const fields = {
  firstName: '',
  lastName: '',
  userName: '',
  password: ''
};

const PatientCreate = () => <PatientForm fields={fields} />;

export default PatientCreate;
