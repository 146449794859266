import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { deleteStaff } from '../../apis/staffApi';
import { iosToDateClinic } from '../../utils/formatTime';

const StaffRow = ({ staff, api, t }) => {
  const navigate = useNavigate();
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setErrors] = useState('');

  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };
  const handleDelete = async () => {
    try {
      const result = await deleteStaff(staff.id);
      if (result.data.statusCode === 200) {
        sessionStorage.setItem('alertMsg', 'deleted');
        setPopupOpen(false);
        api();
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setPopupOpen(false);
      setErrors(error.message);
    }
  };
  return (
    <TableRow
      key={staff.id}
      style={{ cursor: 'unset' }}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      // onClick={(event) => navigte(`/staffs/${staff.id}`)}
    >
      <Dialog
        open={popupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ paddingBottom: '0px' }}>
          <Grid container justifyContent="center">
            <ErrorIcon fontSize="large" />
          </Grid>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4>{t('Are you sure to delete Staff?')}</h4>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" autoFocus onClick={handleDelete}>
                {t('Yes')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" onClick={handleClose}>
                {t('No')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <TableCell
        component="th"
        scope="row"
        style={{
          maxWidth: 70,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {staff.first_name}
      </TableCell>
      <TableCell
        style={{
          maxWidth: 70,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {staff.last_name ? staff.last_name : '-'}
      </TableCell>
      <TableCell>{staff.is_active ? 'Active' : 'Not Active'}</TableCell>
      <TableCell>{iosToDateClinic(staff.created_at)}</TableCell>
      <TableCell>
        <Link to={`/staffs/${staff.id}`}>
          <VisibilityIcon />
        </Link>
        <Link to={`/staffs/${staff.id}/update`}>
          <EditIcon variant="contained" />
        </Link>
        <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={handleClickOpen} />
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(StaffRow);
