import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { deleteProblem } from '../../apis/problemApi';

const ProblemRow = ({ problem, api, t }) => {
  const navigate = useNavigate();
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setErrors] = useState('');

  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };
  const handleDelete = async () => {
    try {
      const result = await deleteProblem(problem.id);
      if (result.data.statusCode === 200) {
        sessionStorage.setItem('alertMsg', 'deleted');
        setPopupOpen(false);
        api();
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setPopupOpen(false);
      setErrors(error.message);
    }
  };
  return (
    <TableRow
      key={problem.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      style={{ cursor: 'unset' }}
      // onClick={(event) => navigte(`/clinics/${problem.id}`)}
    >
      <Dialog
        open={popupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ paddingBottom: '0px' }}>
          <Grid container justifyContent="center">
            <ErrorIcon fontSize="large" />
          </Grid>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4>{t('Are you sure to delete Problem?')}</h4>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" autoFocus onClick={handleDelete}>
                {t('Yes')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" onClick={handleClose}>
                {t('No')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <TableCell>{problem.name ? problem.name : '-'}</TableCell>
      <TableCell>
        <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={handleClickOpen} />
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(ProblemRow);
