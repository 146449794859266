import axiosInstance from '../config/api';

export const getProblemList = async () => {
  const response = await axiosInstance.get(`https://api.dentalcam.app/problems/`);
  return response;
};

export const deleteProblem = async (id) => {
  const response = await axiosInstance.delete(`https://api.dentalcam.app/problems/${id}`);
  return response;
};

export const createProblem = async (fromData) => {
  const response = await axiosInstance.post(`https://api.dentalcam.app/problems`, fromData);
  return response;
};
