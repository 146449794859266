import styled from '@emotion/styled';
import Button from '@mui/material/Button';

const CmsButton = styled(Button)((props) => ({
  textTransform: 'capitalise',
  letterSpacing: '0.5px',
  fontWeight: 600,
  boxShadow: 'none',
  borderRadius: '5px',
  svg: {
    color: props.svg ? 'white' : ''
  }
}));

export default CmsButton;
