import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, useField, useFormikContext } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Stack, TextField, IconButton, InputAdornment, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { withTranslation } from 'react-i18next';
import { createStaff, updateStaff, deleteStaff } from '../../apis/staffApi';

const StaffForm = ({ fields, id, t }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const history = id ? `/staffs/${id}` : '/staffs/';
  const [error, setErrors] = useState('');
  const StaffSchema = Yup.object().shape({
    firstName: Yup.string().required(t('First Name is required')),
    lastName: Yup.string(),
    userName: Yup.string()
      .required(t('Username is required'))
      .matches(/^[a-zA-Z0-9_.-]+$/, t('Special Characters Are Not Allowed')),
    password: Yup.string().required(t('Password is required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fields,
    validationSchema: StaffSchema,
    onSubmit: async (values, actions) => {
      try {
        let result = '';
        if (id) {
          result = await updateStaff(values, id);
        } else {
          result = await createStaff(values);
        }
        if (result.data.statusCode === 200) {
          if (id) {
            sessionStorage.setItem('alertMsg', t('updated'));
          } else {
            sessionStorage.setItem('alertMsg', t('created'));
          }
          navigate('/staffs', { replace: true });
        }
      } catch (error) {
        if (error.statusCode === 401) {
          localStorage.clear();
          sessionStorage.setItem('alertMsg', t('timeout'));
          navigate('/login');
        }
        actions.setSubmitting(false);
        setErrors(error.message);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Container fixed maxWidth="md">
      <div className="pageHeading">
        {/* <RouterLink to={history}>
          <IconButton color="primary" aria-label="upload picture" component="span">
            <ArrowBackIcon />
          </IconButton>
        </RouterLink> */}
        <div style={{ position: 'absolute', left: '-50px' }}>
          <IconButton
            onClick={() => navigate(-1)}
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <h1 className="pageTitle">{id ? t('Update Staff') : t('Create Staff')}</h1>
      </div>
      <FormikProvider value={formik}>
        <p style={{ marginBottom: '20px' }}>{error}</p>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mb: '20px' }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="First Name"
                type="text"
                label={t('First Name')}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && t(errors.firstName)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="Last Name"
                type="text"
                label={t('Last Name')}
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && t(errors.lastName)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="username"
                type="text"
                label={t('Username')}
                {...getFieldProps('userName')}
                error={Boolean(touched.userName && errors.userName)}
                helperText={touched.userName && t(errors.userName)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label={t('Password')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && t(errors.password)}
              />
            </Grid>
          </Grid>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {id ? t('Update') : t('Create')}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Container>
  );
};

export default withTranslation()(StaffForm);
