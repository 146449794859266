// routes
// import Router from './routes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
// theme
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
// import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import DashboardLayout from './layouts/dashboard';
import {
  ProtectedRouteAdmin,
  ProtectedRouteSuperAdmin,
  ProtectedRouteForLoggedIn,
  ProtectedRouteForLogout,
  ProtectedRouteUser,
  ProtectedRouteForDasboard
} from './components/authentication/ProtectedRoute';
import Login from './pages/Login';
import Clinics from './pages/Clinic';
import ClinicCreate from './pages/Clinic/ClinicCreate';
import ClinicUpdate from './pages/Clinic/ClinicUpdate';
import Staff from './pages/Staff';
import StaffCreate from './pages/Staff/StaffCreate';
import StaffUpdate from './pages/Staff/StaffUpdate';
import Logout from './pages/Logout';
import Page404 from './pages/Page404';
import PrivacyPolicy from './pages/PrivacyPolicy';
import StaffView from './pages/Staff/StaffView';
import ClinicsDetail from './pages/Clinic/ClinicsDetail';
import Styles from './css/general.css';
import Patient from './pages/Patient';
import PatientView from './pages/Patient/PatientView';
import PatientUpdate from './pages/Patient/PatientUpdate';
import PatientCreate from './pages/Patient/PatientCreate';
import ContactUs from './pages/ContactUs/index';
import Profile from './pages/Profile/index';
import Footer from './layouts/dashboard/Footer';
import Problem from './pages/Problem';
// ----------------------------------------------------------------------

export default function App() {
  // const alertMsg = sessionStorage.getItem('alertMsg');

  // const [state, setState] = useState({
  //   open: false,
  //   vertical: 'top',
  //   horizontal: 'center'
  // });
  // const { vertical, horizontal, open } = state;
  // const handleClick = () => {
  //   setState({ ...state, open: true });
  // };
  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };
  // useEffect(() => {
  //   if (alertMsg) {
  //     handleClick();
  //     setTimeout(() => {
  //       handleClose();
  //       sessionStorage.removeItem('alertMsg');
  //     }, 3000);
  //   }
  // }, []);

  return (
    <ThemeConfig>
      {/* <ScrollToTop /> */}
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router>
        {/* <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          icon={false}
          key={vertical + horizontal}
        >
          <MuiAlert elevation={6} variant="filled" severity="error">
            not authorized
          </MuiAlert>
        </Snackbar> */}
        <Routes>
          <Route path="/login" element={<ProtectedRouteForLoggedIn />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/" element={<ProtectedRouteForLogout />}>
            <Route path="/" element={<DashboardLayout />}>
              <Route path="/" exact element={<ProtectedRouteForDasboard />} />
              <Route path="/clinics" element={<ProtectedRouteSuperAdmin />}>
                <Route path="/clinics/" element={<Clinics />} />
                <Route path="/clinics/create" element={<ClinicCreate />} />
                <Route path="/clinics/:id" element={<ClinicsDetail />} />
                <Route path="/clinics/:id/update" element={<ClinicUpdate />} />
              </Route>
              <Route path="/problems" element={<ProtectedRouteSuperAdmin />}>
                <Route path="/problems/" element={<Problem />} />
              </Route>
              <Route path="/staffs" element={<ProtectedRouteAdmin />}>
                <Route path="/staffs/" element={<Staff />} />
                <Route path="/staffs/create" element={<StaffCreate />} />
                <Route path="/staffs/:id" element={<StaffView />} />
                <Route path="/staffs/:id/update" element={<StaffUpdate />} />
              </Route>
              <Route path="/patients" element={<ProtectedRouteUser />}>
                <Route path="/patients/" element={<Patient />} />
                <Route path="/patients/create" element={<PatientCreate />} />
                <Route path="/patients/:id" element={<PatientView />} />
                <Route path="/patients/:id/update" element={<PatientUpdate />} />
              </Route>
              <Route path="/profile/" element={<Profile />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/logout" element={<Logout />} />
            </Route>
          </Route>
          <Route path="*" element={<Page404 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
      <Footer />
    </ThemeConfig>
  );
}
