import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LoadingButton } from '@mui/lab';
import { withTranslation } from 'react-i18next';
import { FormikProvider, useFormik, Form } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, CircularProgress, Container, IconButton, TextField } from '@mui/material';
import axiosInstance from '../../config/api';
import PageWrapper from '../../components/Common Components/PageStyle';
import { SendEmail } from '../../apis/contactUs';

function ContactUs({ t }) {
  const [DataOfContactUs, setDataOfContactUs] = useState([]);
  const FetchData = async () => {
    axiosInstance
      .get(`https://api.dentalcam.app/auth/contactus`)
      .then((response) => setDataOfContactUs(response.data));
  };
  const alertMsg = sessionStorage.getItem('alertMsg');
  const navigate = useNavigate();
  const [error, setErrors] = useState('');
  useEffect(() => {
    FetchData();
  }, []);
  const { data } = DataOfContactUs;

  const fields = {
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  };
  const ContactShema = Yup.object().shape({
    firstName: Yup.string().required(t('First Name is required')),
    lastName: Yup.string().required(t('Last Name is requied')),
    email: Yup.string().email(t('Invalid email format')).required(t('Email is required')),
    message: Yup.string().required(t('Message is requied'))
  });
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleClick = () => {
    setState({ ...state, open: true });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fields,
    validationSchema: ContactShema,
    onSubmit: async (value, actions) => {
      try {
        const Email = await SendEmail(value);
        if (Email.data.statusCode) {
          actions.resetForm();
          sessionStorage.setItem('alertMsg', t('sent'));
          handleClick();
        } else {
          sessionStorage.setItem('alertMsg', t('error'));
        }
      } catch (error) {
        console.log(error);
        setErrors(error.message);
      }
    }
  });
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  useEffect(() => {
    if (alertMsg === t('sent')) {
      handleClick();
      setTimeout(() => {
        handleClose();
        sessionStorage.removeItem('alertMsg');
      }, 1500);
    }
  }, [alertMsg]);
  const { isSubmitting, handleSubmit, getFieldProps, touched, errors } = formik;
  return (
    <div>
      {DataOfContactUs.length === 0 ? (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      ) : (
        <Container fixed maxWidth="md">
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            icon={false}
            key={vertical + horizontal}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {t('Your Message is Successfully Sent')}
            </MuiAlert>
          </Snackbar>
          <div className="pageHeading">
            <span style={{ position: 'absolute', left: '-50px' }}>
              <IconButton color="primary" onClick={() => navigate(-1)} component="span">
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h1 className="pageTitle">{t('Contact Us')}</h1>
          </div>
          <FormikProvider value={formik}>
            <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ mb: '20px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    {...getFieldProps('firstName')}
                    type="text"
                    label={t('First Name')}
                    error={Boolean(touched.firstName && t(errors.firstName))}
                    helperText={touched.firstName && t(errors.firstName)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    {...getFieldProps('lastName')}
                    label={t('Last Name')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && t(errors.lastName)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...getFieldProps('email')}
                    type="email"
                    label={t('Email')}
                    error={Boolean(touched.Email && errors.Email)}
                    helperText={touched.Email && t(errors.Email)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...getFieldProps('message')}
                    multiline
                    rows={7}
                    inputProps={{
                      maxLength: 600
                    }}
                    label={t('Message')}
                    error={Boolean(touched.Message && errors.Message)}
                    helperText={touched.Message && t(errors.Message)}
                  />
                </Grid>
              </Grid>
              <LoadingButton
                size="large"
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {t('Submit')}
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Container>
      )}
    </div>
  );
}

export default withTranslation()(ContactUs);
