import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MuiAlert from '@mui/material/Alert';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress, Grid, InputAdornment, TablePagination, TextField } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { getClinicList } from '../../apis/clinicApis';
import ClinicRow from './Rows';
import ListView from '../../components/ListView';
import CustomTablePagination from '../../components/Common Components/CustomPagination';
import SearchField from '../../components/Common Components/SearchField';

function Clinics({ t }) {
  const [clinics, setClinics] = useState([]);
  const alertMsg = sessionStorage.getItem('alertMsg');
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [error, setErrors] = useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const [pageination, setPageination] = useState('forward');
  const [pageDetails, setPageDetails] = useState({
    pageStart: 1,
    pageEnd: 10
  });
  const [pageSize, setPageSize] = useState(10);
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [showPage, setShowPage] = useState();
  const [checkFirst, setCheckFirst] = useState(true);
  const handleClick = () => {
    getClinics();
    setState({ ...state, open: true });
  };
  const getClinics = async (pageSize, page, search, useLoader) => {
    if (useLoader) {
      setLoader(true);
    }
    try {
      const response = await getClinicList(pageSize, page || 1, search || '', useLoader);
      if (response.data.statusCode === 200) {
        setClinics(response.data.data.records);
        setShowPage(response.data.data.totalRecords);
        setLoader(false);
        if (pageination === 'forward') {
          if (page > 1) {
            const pageStart = pageDetails.pageEnd + 1;
            let pageEnd = pageStart + 9;
            pageEnd = pageEnd > showPage ? showPage : pageEnd;
            setPageDetails({
              pageStart,
              pageEnd
            });
          } else {
            let pageEnd = 10;
            pageEnd = pageEnd > showPage ? showPage : pageEnd;
            setPageDetails({
              pageStart: 1,
              pageEnd
            });
          }
        } else {
          const pageStart = pageDetails.pageStart - 10;
          // let pageEnd = pageDetails.pageEnd - 10;
          let pageEnd = pageStart + 9;
          pageEnd = pageEnd > showPage ? showPage : pageEnd;
          setPageDetails({
            pageStart,
            pageEnd
          });
        }
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', t('timeout'));
        navigate('/login');
      }
      setLoader(false);
      console.log(error);
      setErrors(error.message);
    }
  };

  useEffect(() => {
    if (checkFirst) {
      setCheckFirst(false);
    } else {
      getClinics(pageSize, page, search, true);
    }
  }, [pageSize, page, search]);

  useEffect(() => {
    if (alertMsg !== null && loader) {
      handleClick();
      setTimeout(() => {
        handleClose();
        sessionStorage.removeItem('alertMsg');
      }, 1500);
    }
  }, [loader]);

  useEffect(() => {
    if (alertMsg) {
      handleClick();
      setTimeout(() => {
        handleClose();
        sessionStorage.removeItem('alertMsg');
      }, 1500);
    }
    getClinics(pageSize, page, search, true);
  }, [alertMsg]);

  const navigate = useNavigate();

  const searchData = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const nextPage = () => {
    setPageination('forward');
    setPage(page + 1);
  };

  const backPage = () => {
    setPageination('backward');
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        icon={false}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            alertMsg === t('updated') || alertMsg === t('created') ? t('success') : t('error')
          }
        >
          {`Your data is ${alertMsg} successfully`}
        </MuiAlert>
      </Snackbar>
      {!loader ? (
        // <ListView
        //   TableHeader={['First Name', 'Last Name', 'Plan', 'Expiry Date', 'Active']}
        //   TableData={clinics}
        //   TableOnClick=""
        // />
        <>
          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/clinics/create">
              <Button variant="contained">{t('Create')}</Button>
            </Link>

            <SearchField searchData={searchData} search={search} />
          </Grid>
          <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Clinic Name')}</TableCell>
                  <TableCell>{t('Email')}</TableCell>
                  <TableCell>{t('Plan')}</TableCell>
                  <TableCell>{t('Expiry Date')}</TableCell>
                  <TableCell>{t('Active')}</TableCell>
                  <TableCell>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clinics.length > 0 ? (
                  clinics.map((clinic) => (
                    <ClinicRow
                      key={clinic.id}
                      api={() => getClinics(pageSize, page, search)}
                      clinic={clinic}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      {t('No Data Found')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <CustomTablePagination
            component="div"
            pageProp={page}
            rows={rowsPerPage}
            countlength={clinics.length}
            onPageChangeProp={handleChangePage}
            onRowsPerPageChangeProp={handleChangeRowsPerPage}
          /> */}
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <h5>
              {pageDetails.pageStart} -
              {pageDetails.pageEnd >= showPage ? showPage : pageDetails.pageEnd} of {showPage}
            </h5>
            <Grid>
              <IconButton
                onClick={backPage}
                disabled={page === 1 || loader}
                size="small"
                style={{ color: `${page === 1 ? 'grey' : 'blue'}` }}
                aria-label="Back"
                component="span"
              >
                <ArrowBackIosNewIcon
                  style={{ color: `${page === 1 ? 'grey' : 'blue'}` }}
                  fontSize="8px"
                />
              </IconButton>

              <IconButton
                disabled={pageDetails.pageEnd >= showPage || loader}
                onClick={nextPage}
                size="small"
                style={{ color: `${pageDetails.pageEnd >= showPage ? 'grey' : 'blue'}` }}
                aria-label="Next"
                component="span"
              >
                <ArrowForwardIosIcon
                  style={{ color: `${pageDetails.pageEnd >= showPage ? 'grey' : 'blue'}` }}
                  fontSize="8px"
                />
              </IconButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
export default withTranslation()(Clinics);
