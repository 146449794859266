import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TableRow from '@mui/material/TableRow';
import { Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { iosToDate } from '../../utils/formatTime';
import PageWrapper from '../../components/Common Components/PageStyle';
import CmsBtn from '../../components/Common Components/Button/index';

const Profile = ({ t }) => {
  const userDetails = localStorage.getItem('userInfo');
  const dateOfUser = JSON.parse(userDetails);
  const [profile, setProfile] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    setProfile(dateOfUser);
  }, []);
  return (
    <PageWrapper>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image="/static/mock-images/avatars/avatar_default.jpg"
              alt="user_name"
            />
            <CardContent>
              <Table>
                <TableBody>
                  {profile.clinicName ? (
                    <TableRow>
                      <TableCell>{t('Clinic Name')}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>
                        {profile.clinicName}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {profile.firstName ? (
                    <TableRow>
                      <TableCell>{t('Frist Name')}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>
                        {profile.firstName}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {profile.lastName ? (
                    <TableRow>
                      <TableCell>{t('Last Name')}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>
                        {profile.lastName}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {profile.clinicEmail ? (
                    <TableRow>
                      <TableCell>{t('Email')}</TableCell>
                      <TableCell>{profile.clinicEmail ? profile.clinicEmail : '-'}</TableCell>
                    </TableRow>
                  ) : null}
                  {profile.planName ? (
                    <TableRow>
                      <TableCell>{t('Plan Name')}</TableCell>
                      <TableCell>{profile.planName ? profile.planName : '-'}</TableCell>
                    </TableRow>
                  ) : null}
                  {profile.expiryDate ? (
                    <TableRow>
                      <TableCell>{t('Expiry Date')}</TableCell>
                      <TableCell>
                        {profile.expiryDate ? iosToDate(profile.expiryDate) : '-'}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {profile.password ? (
                    <TableRow>
                      <TableCell>{t('Password')}</TableCell>
                      <TableCell
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start'
                        }}
                      >
                        <div style={{ minWidth: '70px' }}>
                          {showPassword ? profile.password : <TableRow>*****</TableRow>}
                        </div>
                        <div>
                          {showPassword ? (
                            <VisibilityIcon onClick={() => setShowPassword(false)} />
                          ) : (
                            <VisibilityOffIcon onClick={() => setShowPassword(true)} />
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default withTranslation()(Profile);
