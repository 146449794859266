import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { iosToDateClinic } from '../../utils/formatTime';
import { deleteClinic, getClinicList } from '../../apis/clinicApis';

const ClinicRow = ({ clinic, api, t }) => {
  const navigate = useNavigate();
  const [error, setErrors] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);

  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };
  const handleDelete = async () => {
    try {
      const result = await deleteClinic(clinic.id);
      if (result.data.statusCode === 200) {
        sessionStorage.setItem('alertMsg', t('deleted'));
        setPopupOpen(false);
        api();
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', t('timeout'));
        navigate('/login');
      }
      setPopupOpen(false);
      setErrors(error.message);
    }
  };
  return (
    <TableRow
      key={clinic.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      style={{ cursor: 'unset' }}
      // onClick={(event) => navigte(`/clinics/${clinic.id}`)}
    >
      <Dialog
        open={popupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ paddingBottom: '0px' }}>
          <Grid container justifyContent="center">
            <ErrorIcon fontSize="large" />
          </Grid>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4>{t('Are you sure to delete Clinic?')}</h4>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" autoFocus onClick={handleDelete}>
                {t('Yes')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" onClick={handleClose}>
                {t('No')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <TableCell
        className="tableCellHover"
        onClick={() => navigate(`/clinics/${clinic.id}`)}
        component="th"
        scope="row"
        style={{
          maxWidth: 70,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {clinic.clinicName ? clinic.clinicName : '-'}
      </TableCell>
      <TableCell
        className="tableCellHover"
        onClick={() => navigate(`/clinics/${clinic.id}`)}
        style={{
          maxWidth: 100,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {clinic.clinicEmail ? clinic.clinicEmail : '-'}
      </TableCell>
      <TableCell className="tableCellHover" onClick={() => navigate(`/clinics/${clinic.id}`)}>
        {clinic.clinicPlan ? clinic.clinicPlan : '-'}
      </TableCell>
      <TableCell className="tableCellHover" onClick={() => navigate(`/clinics/${clinic.id}`)}>
        {iosToDateClinic(clinic.clinicExipry)}
      </TableCell>
      <TableCell className="tableCellHover" onClick={() => navigate(`/clinics/${clinic.id}`)}>
        {clinic.isActive ? 'Active' : 'Not Active'}
      </TableCell>
      <TableCell>
        <Link to={`/clinics/${clinic.id}`}>
          <VisibilityIcon />
        </Link>
        <Link to={`/clinics/${clinic.id}/update`}>
          <EditIcon />
        </Link>
        <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={handleClickOpen} />
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(ClinicRow);
