import * as React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import ErrorIcon from '@mui/icons-material/Error';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Container
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { withTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getStaff, deleteStaff } from '../../apis/staffApi';
import PageWrapper from '../../components/Common Components/PageStyle';

function StaffView({ t }) {
  const [staff, setStaff] = useState();
  const { id } = useParams();
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setErrors] = useState('');
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };

  const handleDelete = async () => {
    try {
      const result = await deleteStaff(id);
      if (result.data.statusCode === 200) {
        sessionStorage.setItem('alertMsg', 'deleted');
        navigate('/staffs', { replace: true });
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setPopupOpen(false);
      setErrors(error.message);
    }
  };
  const getStaffView = async () => {
    try {
      const response = await getStaff(id);
      if (response.data.statusCode === 200) {
        setStaff(response.data.data);
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setErrors(error.message);
    }
  };
  useEffect(() => {
    getStaffView();
  }, []);
  return (
    <div fixed maxWidth="md">
      {staff ? (
        <Grid container alignItems="flex-start" direction="row" md={12}>
          <Grid item xs={11}>
            <PageWrapper>
              <Grid container justifyContent="center" alignItems="baseline" md={12}>
                <IconButton
                  onClick={() => navigate(-1)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <ArrowBackIcon />
                </IconButton>
                <Grid item md={6} lg={6} xl={6}>
                  <div style={{ marginBottom: '10px', display: 'flex' }}>
                    <h1>{t('View Staff')}</h1>
                  </div>
                  <Card>
                    <CardContent>
                      <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t('Username')}</TableCell>
                            <TableCell>{staff.userName === '' ? '-' : staff.userName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('First Name')}</TableCell>
                            <TableCell>{staff.firstName === '' ? '-' : staff.firstName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('Last Name')}</TableCell>
                            <TableCell>{staff.lastName === '' ? '-' : staff.lastName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('Active')}</TableCell>
                            <TableCell>{staff.is_active ? 'Active' : 'In Active'}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.5fr 0.5fr',
                          columnGap: '15px'
                        }}
                      >
                        <Button
                          fullWidth
                          onClick={() => navigate(`/staffs/${id}/update`)}
                          variant="contained"
                        >
                          {t('Update')}
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          style={{ backgroundColor: 'red' }}
                          onClick={handleClickOpen}
                        >
                          {t('Delete')}
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </PageWrapper>
          </Grid>

          {/* <Link to={`/staffs/${id}/update`} state={staff}>
            <Button variant="contained">Update</Button>
          </Link> */}
          {/* <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleClickOpen}>
            Delete
          </Button> */}
          <Dialog
            open={popupOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{ paddingBottom: '0px' }}>
              <Grid container justifyContent="center">
                <ErrorIcon fontSize="large" />
              </Grid>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4>{t('Are you sure to delete Staff?')}</h4>
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" onClick={handleDelete} autoFocus>
                    {t('Yes')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" onClick={handleClose}>
                    {t('No')}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          {/* <Grid container spacing={1} style={{ margin: '10px 0px' }}>
            <Grid item xs={2} spacing={3}>
              First Name
            </Grid>
            <Grid item xs={10} spacing={3}>
              {staff.firstName === '' ? '-' : staff.firstName}
            </Grid>
            <Grid item xs={2} spacing={3}>
              Last Name
            </Grid>
            <Grid item xs={10} spacing={3}>
              {staff.lastName === '' ? '-' : staff.lastName}
            </Grid>
            <Grid item xs={2} spacing={3}>
              User Name
            </Grid>
            <Grid item xs={10} spacing={3}>
              {staff.userName === '' ? '-' : staff.userName}
            </Grid>
            <Grid item xs={2} spacing={3}>
              Actice
            </Grid>
            <Grid item xs={10} spacing={3}>
              {staff.is_active ? 'Active' : 'In Active'}
            </Grid>
          </Grid> */}
        </Grid>
      ) : (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
export default withTranslation()(StaffView);
