import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function iosToDate(date, divider = '-') {
  date = new Date(date);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  return `${dt}${divider}${month}${divider}${year}`;
}

export function iosToDateValue(date = new Date()) {
  date = new Date(date);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  return `${year}-${month}-${dt}`;
}

export function iosToDateClinic(date) {
  let newDate = date.split(' ');
  newDate = newDate[0].split('-');
  if (newDate[2] < 10 && newDate[2].length < 2) {
    newDate[2] = `0${newDate[2]}`;
  }
  if (newDate[1] < 10 && newDate[1].length < 2) {
    newDate[1] = `0${newDate[1]}`;
  }

  return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
}

export function iosToDateValueClinic(date) {
  // date = new Date(date);
  let newDate = date.split(' ');
  newDate = newDate[0].split('-');
  if (newDate[2] < 10 && newDate[2].length < 2) {
    newDate[2] = `0${newDate[2]}`;
  }
  if (newDate[1] < 10 && newDate[1].length < 2) {
    newDate[1] = `0${newDate[1]}`;
  }

  return `${newDate[0]}-${newDate[1]}-${newDate[2]}`;
}

export function iosToDateReverse(date, divider = '-') {
  date = new Date(date);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  return `${year}${divider}${month}${divider}${dt}`;
}
