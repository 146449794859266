import * as Yup from 'yup';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, useField, useFormikContext } from 'formik';
import { Stack, TextField, IconButton, InputAdornment, Container } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { withTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { createPatient, updatePatient } from '../../apis/patientApi';

function PatientForm({ fields, t }) {
  const navigate = useNavigate();
  const [error, setErrors] = useState('');
  const history = fields.id ? `/patients/${fields.id}` : '/patients/';

  const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={t('Date Of Birth')}
          autoComplete="Date of Birth"
          {...field}
          {...props}
          {...getFieldProps('dob')}
          error={Boolean(touched.dob && errors.dob)}
          helperText={touched.dob && errors.dob}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue('dob', val);
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </LocalizationProvider>
    );
  };
  const PatientSchema = Yup.object().shape({
    firstName: Yup.string().required(t('First Name is required')),
    lastName: Yup.string(),
    dob: Yup.date()
      .max(31 - 12 - 9999, t('date is not acceptable'))
      .required(t('Date of Birth is required'))
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fields,
    validationSchema: PatientSchema,
    onSubmit: async (values, actions) => {
      try {
        let result = '';
        if (fields.id) {
          result = await updatePatient(values, fields.id);
        } else {
          result = await createPatient(values);
        }
        if (result.data.statusCode === 200) {
          if (fields.id) {
            sessionStorage.setItem('alertMsg', t('updated'));
          } else {
            sessionStorage.setItem('alertMsg', t('created'));
          }
          navigate('/patients', { replace: true });
        }
      } catch (error) {
        if (error.statusCode === 401) {
          localStorage.clear();
          sessionStorage.setItem('alertMsg', t('timeout'));
          navigate('/login');
        }
        actions.setSubmitting(false);
        setErrors(error.message);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Container fixed maxWidth="md">
      <div className="pageHeading">
        <span style={{ position: 'absolute', left: '-50px' }}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => navigate(-1)}
            component="span"
          >
            <ArrowBackIcon />
          </IconButton>
        </span>
        <h1 className="pageTitle">{fields.id ? t('Update Patient') : t('Create Patient')}</h1>
      </div>
      <FormikProvider value={formik}>
        <p style={{ marginBottom: '20px' }}>{error}</p>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mb: '20px' }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="First Name"
                type="text"
                label={t('First Name')}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && t(errors.firstName)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="Last Name"
                type="text"
                label={t('Last Name')}
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && t(errors.lastName)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="Date"
                fullWidth
                {...(fields.dob !== '' ? { ...getFieldProps('dob') } : null)}
                error={Boolean(touched.dob && errors.dob)}
                helperText={touched.dob && t(errors.dob)}
              />
              {/* <TextField
                fullWidth
                type="date"
                {...getFieldProps('dob')}
                error={Boolean(touched.dob && errors.dob)}
                helperText={touched.dob && errors.dob}
              /> */}
              {/* <DatePickerField name="date" /> */}
            </Grid>
          </Grid>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {fields.id ? t('Update') : t('Create')}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Container>
  );
}

export default withTranslation()(PatientForm);
