import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { withTranslation } from 'react-i18next';
import SearchField from '../../components/Common Components/SearchField';
import CustomTablePagination from '../../components/Common Components/CustomPagination';
import StaffRow from './Rows';
import { getStaffList } from '../../apis/staffApi';

const Staff = ({ t }) => {
  const [staffs, setStaffs] = useState([]);
  const alertMsg = sessionStorage.getItem('alertMsg');
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [error, setErrors] = useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleClick = () => {
    setState({ ...state, open: true });
  };
  const getStaff = async () => {
    setLoader(true);
    // console.log('navigator.onLine---');
    // console.log(navigator.onLine);
    try {
      const response = await getStaffList();
      if (response.data.statusCode === 200) {
        setStaffs(response.data.data);
        setLoader(false);
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      setLoader(false);
      setErrors(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    getStaff();
  }, []);

  useEffect(() => {
    if (alertMsg && loader === true) {
      handleClick();
      setTimeout(() => {
        handleClose();
        sessionStorage.removeItem('alertMsg');
      }, 1500);
    }
  }, [loader, alertMsg]);

  const searchData = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div>
      {!loader ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            icon={false}
            key={vertical + horizontal}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={
                alertMsg === t('updated') || alertMsg === t('created') ? t('success') : t('error')
              }
            >
              {`Your data is ${alertMsg} successfully`}
            </MuiAlert>
          </Snackbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <SearchField searchData={searchData} search={search} />
            <Link to="/staffs/create">
              <Button variant="contained">{t('Create')}</Button>
            </Link>
          </Grid>
          <p style={{ marginBottom: '10px', marginTop: '10px' }}>{error}</p>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('First Name')}</TableCell>
                  <TableCell>{t('Last Name')}</TableCell>
                  <TableCell>{t('Active')}</TableCell>
                  <TableCell>{t('Created At')}</TableCell>
                  <TableCell>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staffs.length > 0 ? (
                  staffs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .filter(
                      (item) =>
                        item.first_name.toUpperCase().includes(search.toUpperCase()) ||
                        item.last_name.toUpperCase().includes(search.toUpperCase())
                    )
                    .map((staff) => (
                      <StaffRow key={staff.id} api={() => getStaff()} staff={staff} />
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      {t('No Data Found')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination
            component="div"
            pageProp={page}
            rows={rowsPerPage}
            countlength={staffs.length}
            onPageChangeProp={handleChangePage}
            onRowsPerPageChangeProp={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <div className="loaderDiv">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Staff);
