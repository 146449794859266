import React from 'react';
import StaffForm from './StaffForm';

const fields = {
  firstName: '',
  lastName: '',
  userName: '',
  password: ''
};
const StaffCreate = () => <StaffForm fields={fields} />;
export default StaffCreate;
