import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import { login } from '../apis/authenticate';
import Page from '../components/Page';
import { getUserRole } from '../config/api';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const alertMsg = sessionStorage.getItem('alertMsg');
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const [alert, setAlert] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const { openAlert } = alert;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleClick = () => {
    setState({ ...state, open: true });
  };

  const user = localStorage.getItem('user_role');
  const LogininCheack = () => {
    if (user) {
      if (getUserRole() === 'super_admin') {
        navigate('/clinics', { replace: true });
      } else if (getUserRole() === 'clinic_admin') {
        navigate('/staffs', { replace: true });
      } else {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'error');
      }
    }
  };
  useEffect(() => {
    if (alertMsg) {
      handleClick();
      setTimeout(() => {
        handleClose();
        sessionStorage.removeItem('alertMsg');
      }, 3000);
    }
    LogininCheack();
  }, []);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        icon={false}
        key={vertical + horizontal}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          Session Timeout
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        icon={false}
        key={vertical + horizontal}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          not authorized
        </MuiAlert>
      </Snackbar>
      <RootStyle title="DentalCam">
        {/* <AuthLayout>
          Don’t have an account? &nbsp;
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
            Get started
          </Link>
        </AuthLayout> */}

        <MHidden width="mdDown">
          <SectionStyle>
            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Dental Plus
            </Typography> */}
            <img
              src="/static/logo.png"
              alt="logo"
              style={{
                margin: '0 25%',
                height: '232px',
                width: '232px'
              }}
            />
            <h2 style={{ textAlign: 'center' }}>
              <span
                style={{
                  background:
                    'linear-gradient(90deg, rgba(171,48,177,1) 0%, rgba(91,114,180,1) 70%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                DentalCam
              </span>
            </h2>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to DentalCam
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
            </Stack>
            {/* <AuthSocial /> */}

            <LoginForm />

            {/* <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="register">
                  Get started
                </Link>
              </Typography>
            </MHidden> */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}
