// scroll bar
// import 'simplebar/src/simplebar.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18next';
import { HelmetProvider } from 'react-helmet-async';

//
import App from './App';

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <Suspense fallback={<div>loading...</div>}>
      <App />
    </Suspense>
  </HelmetProvider>,
  document.getElementById('root')
);
