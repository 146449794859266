/* eslint-disable no-unused-expressions */
import React from 'react';
import { jsPDF } from 'jspdf';
import { withTranslation } from 'react-i18next';

const getBase64FromUrl = (urls) => {
  const ImageData = urls.map(async (url) => {
    const dummyUrl = `${url}?r=${Math.floor(Math.random() * 100000)}`;
    const data = await fetch(dummyUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  });
  return ImageData;
};
export const generatePDF = (
  arrImageUrls,
  perPageImage,
  patient,
  notes,
  selectDate,
  iosToDate,
  handleExportComplete,
  save,
  OPT,
  t
) => {
  const Doc = jsPDF(OPT ? 'letter' : 'a4');
  const ImageSet = {
    0: OPT ? [{ x: 15, y: 35, w: 270, z: 150 }] : [{ x: 15, y: 55, w: 180, z: 200 }],
    1: [
      { x: 10, y: 55, w: 190, z: 100 },
      { x: 10, y: 160, w: 190, z: 100 }
    ],
    2: [
      { x: 10, y: 55, w: 190, z: 100 },
      { x: 10, y: 160, w: 90, z: 100 },
      { x: 110, y: 160, w: 90, z: 100 }
    ],
    3: [
      { x: 20, y: 55, w: 80, z: 100 },
      { x: 110, y: 55, w: 80, z: 100 },
      { x: 20, y: 160, w: 80, z: 100 },
      { x: 110, y: 160, w: 80, z: 100 }
    ]
  };
  const noOfPages = Math.ceil(arrImageUrls.length / ImageSet[perPageImage].length);
  let j = 0;
  const res = getBase64FromUrl(arrImageUrls);
  const imageDataConverted = [];
  Promise.all(res.map((result) => result.then((res2) => imageDataConverted.push(res2)))).then(
    () => {
      for (let i = 1; i <= noOfPages; i += 1) {
        Doc.setFontSize(10);
        Doc.text(10, 10, `${t('NameAndSurname')}: ${patient.firstName} ${patient.lastName}`);

        patient.dob !== null && patient.dob !== ''
          ? Doc.text(10, 15, `${t('DateOfBirth')}: ${iosToDate(patient.dob)}`)
          : null;

        // Doc.text(10, 15, `DOB:  ${iosToDate(patient.dob)}`);
        Doc.text(10, 20, `${t('ImageDate')}: ${iosToDate(selectDate)}`);
        if (notes.length > 0) {
          Doc.text(130, 10, `${t('Note')}:`);
          const splitText = Doc.splitTextToSize(`${notes}`, 70);
          Doc.text(130, 15, splitText);
        }
        Doc.setLineWidth(0.7);
        OPT ? Doc.line(10, 28, 290, 28) : Doc.line(10, 28, 200, 28);
        // DOc.text(200, 25, `Name: ${patient.firstName}`, 'right');
        for (let item = 0; item < ImageSet[perPageImage].length; item += 1) {
          if (j in imageDataConverted) {
            Doc.addImage(
              imageDataConverted[j],
              ImageSet[perPageImage][item].x,
              ImageSet[perPageImage][item].y,
              ImageSet[perPageImage][item].w,
              ImageSet[perPageImage][item].z
            );
            j += 1;
          }
        }
        if (i !== noOfPages) {
          Doc.addPage();
        }
      }
      save === 'open' ? window.open(Doc.output('bloburl'), '_blank') : null;
      save === 'save' ? Doc.save(`${patient.firstName}.pdf`) : null;
      handleExportComplete();
    }
  );
};
