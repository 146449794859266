import { iosToDateValue } from '../../utils/formatTime';
import ClinicForm from './ClinicForm';

const fields = {
  clinicName: '',
  clinicEmail: '',
  userName: '',
  clinicPlanId: '',
  password: '',
  expiryDate: ''
};
const ClinicCreate = () => <ClinicForm fields={fields} />;

export default ClinicCreate;
