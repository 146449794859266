import axiosInstance from '../config/api';

export const getPatientList = async (pageSize, page, search) => {
  const response = await axiosInstance.get(
    `https://api.dentalcam.app/patient/?searchTerm=${search}&pageSize=10&pageNumber=${page}`
  );
  return response;
};

export const getPatient = async (id) => {
  const response = await axiosInstance.get(`https://api.dentalcam.app/patient/${id}`);
  return response;
};

export const deletePatient = async (id) => {
  const response = await axiosInstance.delete(`https://api.dentalcam.app/patient/${id}`);
  return response;
};

export const updatePatient = async (fromData, id) => {
  const response = await axiosInstance.put(`https://api.dentalcam.app/patient/${id}`, fromData);
  return response;
};

export const createPatient = async (fromData) => {
  const response = await axiosInstance.post(`https://api.dentalcam.app/patient/create`, fromData);
  return response;
};
export const AddImageApi = async (fromData, id) => {
  const response = await axiosInstance.post(
    `https://api.dentalcam.app/patient/image/${id}`,
    fromData
  );
  return response;
};
export const OptImageApi = async (fromData, id) => {
  const response = await axiosInstance.post(
    `https://api.dentalcam.app/patient/otpimage/${id}`,
    fromData
  );
  return response;
};
export const UploadImageApi = async (files, Opt) => {
  const response = await axiosInstance.post(
    `https://api.dentalcam.app/patient/uploadImages`,
    files,
    Opt
  );
  return response;
};
export const DeletePhtotoApi = async (id) => {
  const response = await axiosInstance.delete(`https://api.dentalcam.app/patient/image/${id}`);
  return response;
};

export const DeleteObservation = async (subID) => {
  const response = await axiosInstance.delete(
    `https://api.dentalcam.app/patient/observation/${subID}`
  );

  return response;
};
