import axiosInstance from '../config/api';

export const getClinicList = async (pageSize, page, search) => {
  const response = await axiosInstance.get(
    `https://api.dentalcam.app/clinic?searchTerm=${search}&pageSize=10&pageNumber=${page}`
  );
  return response;
};

export const createClinic = async (formData) => {
  const response = await axiosInstance.post('https://api.dentalcam.app/clinic/create', formData);
  return response;
};

export const getClinic = async (id) => {
  const response = await axiosInstance.get(`https://api.dentalcam.app/clinic/${id}`);
  return response;
};

export const updateClinic = async (formData, id) => {
  const response = await axiosInstance.put(`https://api.dentalcam.app/clinic/${id}`, formData);
  return response;
};

export const deleteClinic = async (id) => {
  const response = await axiosInstance.delete(`https://api.dentalcam.app/clinic/${id}`);
  return response;
};

export const getPlans = async () => {
  const response = await axiosInstance.get(`https://api.dentalcam.app/auth/plans`);
  return response;
};
