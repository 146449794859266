import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PatientForm from './PatientForm';
import { iosToDate, iosToDateValue } from '../../utils/formatTime';

function PatientUpdate() {
  const { state } = useLocation();
  const [fields, setFields] = useState(state);
  fields.dob = iosToDateValue(fields.dob);
  return <PatientForm fields={fields} />;
}

export default PatientUpdate;
