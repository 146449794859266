import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import ClinicForm from './ClinicForm';
import { getClinic } from '../../apis/clinicApis';
import { iosToDateValueClinic } from '../../utils/formatTime';

const ClinicUpdate = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [error, setErrors] = useState('');
  const [formData, setFormData] = useState({
    clinicName: '',
    clinicEmail: '',
    userName: '',
    clinicPlanId: '',
    password: '',
    expiryDate: ''
  });
  const { id } = useParams();
  const getClinicUpdate = async () => {
    try {
      const response = await getClinic(id);
      if (response.data.statusCode === 200) {
        if (Object.keys(formData).every((value) => formData[value] === '')) {
          response.data.data.expiryDate = iosToDateValueClinic(response.data.data.expiryDate);
          // console.log(response.data.data, 'response.data.data');
          setFormData(() => ({
            clinicName: response.data.data.clinicName,
            clinicEmail: response.data.data.clinicEmail,
            userName: response.data.data.clinicUsername,
            clinicPlanId: response.data.data.planId,
            password: response.data.data.password,
            expiryDate: iosToDateValueClinic(response.data.data.expiryDate)
          }));
        }
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      console.log(error);
      setErrors(error.message);
    }
  };
  if (state === null) {
    getClinicUpdate();
  }
  return formData === null ? (
    <div className="loaderDiv">
      <CircularProgress />
    </div>
  ) : (
    <ClinicForm fields={formData} id={id} errordata={error} />
  );
};

export default ClinicUpdate;
