import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import { getUserRole } from '../../config/api';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

let sidebar = [];

if (getUserRole() === 'super_admin') {
  sidebar = [
    {
      title: 'Clinics',
      path: '/clinics',
      icon: getIcon(pieChart2Fill)
    },
    {
      title: 'logout',
      path: '/logout',
      icon: getIcon(lockFill)
    }
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon(alertTriangleFill)
    // }
  ];
} else if (getUserRole() === 'clinic_admin') {
  sidebar = [
    // {
    //   title: 'dashboard',
    //   path: '/dashboard/app',
    //   icon: getIcon(pieChart2Fill)
    // },
    {
      title: 'Staff',
      path: '/staffs',
      icon: getIcon(pieChart2Fill)
    },
    {
      title: 'Patients',
      path: '/patients',
      icon: getIcon(pieChart2Fill)
    },
    // {
    //   title: 'user',
    //   path: '/dashboard/user',
    //   icon: getIcon(peopleFill)
    // },
    // {
    //   title: 'product',
    //   path: '/dashboard/products',
    //   icon: getIcon(shoppingBagFill)
    // },
    // {
    //   title: 'blog',
    //   path: '/dashboard/blog',
    //   icon: getIcon(fileTextFill)
    // },
    {
      title: 'logout',
      path: '/logout',
      icon: getIcon(lockFill)
    }
    // {
    //   title: 'register',
    //   path: '/register',
    //   icon: getIcon(personAddFill)
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon(alertTriangleFill)
    // }
  ];
} else {
  sidebar = [
    // {
    //   title: 'dashboard',
    //   path: '/dashboard/app',
    //   icon: getIcon(pieChart2Fill)
    // },
    {
      title: 'Patients',
      path: '/patients',
      icon: getIcon(pieChart2Fill)
    },
    // {
    //   title: 'user',
    //   path: '/dashboard/user',
    //   icon: getIcon(peopleFill)
    // },
    // {
    //   title: 'product',
    //   path: '/dashboard/products',
    //   icon: getIcon(shoppingBagFill)
    // },
    // {
    //   title: 'blog',
    //   path: '/dashboard/blog',
    //   icon: getIcon(fileTextFill)
    // },
    {
      title: 'logout',
      path: '/logout',
      icon: getIcon(lockFill)
    }
    // {
    //   title: 'register',
    //   path: '/register',
    //   icon: getIcon(personAddFill)
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon(alertTriangleFill)
    // }
  ];
}

const sidebarConfig = sidebar;

export default sidebarConfig;
