import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import StaffForm from './StaffForm';
import { getStaff } from '../../apis/staffApi';

const StaffUpdate = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState(state);
  const getStaffs = async () => {
    try {
      const response = await getStaff(id);
      if (response.data.statusCode === 200) {
        if (formData === null) {
          setFormData(response.data.data);
        }
      }
    } catch (error) {
      if (error.statusCode === 401) {
        localStorage.clear();
        sessionStorage.setItem('alertMsg', 'timeout');
        navigate('/login');
      }
      console.log(error);
    }
  };
  if (state === null) {
    getStaffs();
  }

  return formData === null ? (
    <div className="loaderDiv">
      <CircularProgress />
    </div>
  ) : (
    <StaffForm fields={formData} id={id} />
  );
};

export default StaffUpdate;
