import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox } from '@mui/material';
import CmsBtn from '../../components/Common Components/Button';
import { iosToDate } from '../../utils/formatTime';
import { DeletePhtotoApi } from '../../apis/patientApi';

const PatientImages = (props) => {
  const [patientImages, setPatientImages] = useState();
  const [imgPopup, setImgPopup] = useState('');
  const DeletePhoto = async (id) => {
    const result = await DeletePhtotoApi(id);
    console.log(result, 'dsds');
    if (result.status === 200) {
      sessionStorage.setItem('alertMsg', 'deleted');
      props.api();
    }
  };
  useEffect(() => {
    const objImages = [];
    props.images.forEach((item) => {
      const createdAt = iosToDate(item.createdAt);
      const key = objImages.findIndex((x) => x.date === createdAt);
      if (key === -1) {
        objImages.push({
          date: createdAt,
          images: [
            {
              id: item.imageId,
              url: item.imageURL
            }
          ]
        });
      } else {
        objImages[key].images.push({
          id: item.imageId,
          url: item.imageURL
        });
      }
    });
    setPatientImages(objImages);
    props.setPatientImages(objImages);
  }, []);
  return patientImages
    ? patientImages.map((image, index) => (
        <div key={`${image.date}${index}`}>
          <p className="customDivider" key={`${image.date}${index}`}>
            {image.date}
          </p>
          <div className="galleryBox">
            {image.images.map((img) => (
              <div className="imageBox" key={img.id}>
                <img
                  src={img.url}
                  alt="Patient"
                  data-imgid={img.id}
                  onMouseUpCapture={() => {
                    props.setImgPopup(img.url);
                    props.openImage();
                  }}
                />
                <CmsBtn
                  size="small"
                  style={{
                    position: 'absolute',
                    bottom: '0px',
                    backgroundColor: 'red',
                    minWidth: '20px'
                  }}
                  onClick={() => DeletePhoto(img.id)}
                >
                  <DeleteIcon style={{ color: 'white', fontSize: '20px' }} />
                </CmsBtn>
                <Checkbox
                  name="selectedImages"
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.setImageSelected((prev) => [...prev, e.target.value]);
                    } else {
                      props.setImageSelected(
                        props.imagesSelected.filter((imgId) => imgId !== e.target.value)
                      );
                    }
                  }}
                  id={`image${img.id}`}
                  value={img.id}
                  classes={{ root: 'imageSelectionBox' }}
                />
              </div>
            ))}
          </div>
        </div>
      ))
    : null;
};

export default PatientImages;
