import { TextareaAutosize } from '@mui/material';
import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { getAccessToken, getUserRole } from '../../config/api';

export function ProtectedRouteSuperAdmin({ children }) {
  const auth = getAccessToken();
  const userRole = getUserRole();
  if (auth && userRole !== '') {
    return userRole === 'super_admin' ? <Outlet /> : <Navigate to="/" />;
  }
  return <Navigate to="/login" />;
}

export function ProtectedRouteAdmin({ children }) {
  const auth = getAccessToken();
  const userRole = getUserRole();
  if (auth && userRole !== '') {
    return userRole === 'clinic_admin' ? <Outlet /> : <Navigate to="/clinics" />;
  }
  return <Navigate to="/login" />;
}

export function ProtectedRouteUser({ children }) {
  const auth = getAccessToken();
  const userRole = getUserRole();
  if (auth && userRole !== '') {
    return userRole === 'clinic_admin' || userRole === 'clinic_user' ? (
      <Outlet />
    ) : (
      <Navigate to="/clinics" />
    );
  }
  return <Navigate to="/login" />;
}

export function ProtectedRouteForLoggedIn({ children }) {
  const auth = getAccessToken();
  return !auth ? <Outlet /> : <Navigate to="/" />;
}

export function ProtectedRouteForLogout({ children }) {
  const auth = getAccessToken();
  return auth ? <Outlet /> : <Navigate to="/login" />;
}

export function ProtectedRouteForDasboard({ children }) {
  const auth = getAccessToken();
  const userRole = getUserRole();
  if (auth && userRole !== '') {
    if (userRole === 'super_admin') {
      return <Navigate to="/clinics" />;
    }
    return <Navigate to="/patients" />;
  }
  return <Navigate to="/login" />;
}
