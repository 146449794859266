import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import EnFlag from '../../assets/ic_flag_en.svg';
import EsFlag from '../../assets/ic_flag_fr.svg';
import ItFlag from '../../assets/ic_flag_es.svg.svg';

// ----------------------------------------------------------------------
const LANGS = [
  {
    value: 'en',
    label: 'English',
    flag: EnFlag
  },
  {
    value: 'es',
    label: 'Spanish',
    flag: EsFlag
  },
  {
    value: 'it',
    label: 'Italian',
    flag: ItFlag
  }
];
// ----------------------------------------------------------------------

export default function LanguagePopover({ handleLanguageChange }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const LanguageData =
    localStorage.getItem('language') === null || undefined ? '' : localStorage.getItem('language');
  const GetLanguage =
    localStorage.getItem('language') === null || undefined
      ? JSON.parse(JSON.stringify(LANGS[0]))
      : JSON.parse(LanguageData);
  const [currentLanguange, setCurrentLanguage] = useState({
    lang: GetLanguage.value ?? LANGS[0].value,
    flag: GetLanguage.flag ?? LANGS[0].flag
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SelectLang = (selectedOpt) => {
    localStorage.setItem('language', JSON.stringify(selectedOpt));
    setCurrentLanguage({
      lang: selectedOpt.value,
      flag: selectedOpt.flag
    });
    handleLanguageChange(selectedOpt.value);
    setOpen(false);
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        style={{
          borderRadius: 'unset',
          padding: '2px'
        }}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img src={currentLanguange.flag} width="40px" alt="flag" />
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLanguange}
              onClick={() => SelectLang(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon style={{ margin: '0' }}>
                <ListItemAvatar>
                  <Avatar alt="flag" src={option.flag} sx={{ width: 24, height: 24 }} />
                </ListItemAvatar>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
