// const { default: axios } = require('axios');
import axios from 'axios';

const { default: env } = require('./env');

const axiosInstance = axios.create({
  baseURL: env.API_ENDPOINT,
  headers: {
    'content-type': 'application/json; charset=utf-8'
  }
});

export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function getUserRole() {
  return localStorage.getItem('user_role');
}

axiosInstance.interceptors.request.use(
  (config) => {
    let accessToken = getAccessToken();
    let contentType = 'application/json; charset=utf-8';
    if (config.url.includes('/clinic/create')) {
      accessToken = 'P9tHp55QpZpDQGezXgDtTIDvQXyxr2Zl';
    }
    if (config.url.includes('/clinic/') && config.method === 'PUT') {
      accessToken = 'P9tHp55QpZpDQGezXgDtTIDvQXyxr2Zl';
    }
    if (config.url.includes('/problems')) {
      accessToken = 'P9tHp55QpZpDQGezXgDtTIDvQXyxr2Zl';
    }
    if (config.url.includes('/patient/uploadImages')) {
      contentType = 'multipart/form-data';
    }
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
      'content-type': contentType
    };

    if (config.url === 'auth/logout') {
      localStorage.clear();
    }
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

axiosInstance.interceptors.response.use((response) => {
  if (response.config.url.includes('login') && response.data.statusCode === 200) {
    localStorage.setItem('userInfo', JSON.stringify(response.data.data));
    localStorage.setItem('accessToken', response.data.data.token);
    localStorage.setItem('user_role', response.data.data.userRole);
  }

  return response;
});

const err = axiosInstance.interceptors.response.use(undefined, async (err) => {
  await handleError(err);
  return Promise.reject(err.response.data);
});

axiosInstance.interceptors.request.eject(err);
async function handleError(err) {
  const originalReq = err.config;
  if (err) {
    if (err.toJSON().message === 'Network Error') {
      throw new Error('Your internet is not working properly, please check that and try again.');
    }
    if (err.response.statusCode === 401) {
      console.log('Handle 401');
    } else if (err.response.statusCode === 400) {
      console.log('Handle 400');
    }
  } else {
    if (err.response.data && err.response.data.message) {
      console.log('Show Error');
    }
    console.log('Show Error');
  }
}
export default axiosInstance;
