import { InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { withTranslation } from 'react-i18next';

function SearchField({ searchData, search, t }) {
  return (
    <TextField
      label={t('Search')}
      type="search"
      sx={{ width: 300 }}
      size="small"
      onChange={searchData}
      value={search}
      // InputProps={{
      //   endAdornment: (
      //     <InputAdornment>
      //       <SearchIcon />
      //     </InputAdornment>
      //   )
      // }}
    />
  );
}

export default withTranslation()(SearchField);
