import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, useField, useFormikContext } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker, LoadingButton } from '@mui/lab';
import { withTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { iosToDateReverse } from '../../utils/formatTime';
import { createClinic, updateClinic } from '../../apis/clinicApis';

const ClinicForm = ({ fields, id, t }) => {
  const currentDate = iosToDateReverse(new Date(), '-');
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();
  const maxDate = new Date(year + 1, month, day);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setErrors] = useState('');
  const [dateDisble, setDateDisble] = useState(false);
  const history = id ? `/clinics/${id}` : '/clinics/';
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const plan = JSON.parse(localStorage.getItem('plan'));
  const ClinicSchema = Yup.object().shape({
    clinicName: Yup.string().required('clinic Name is required'),
    clinicEmail: Yup.string().email('Invalid email format').required('Email is required'),
    userName: Yup.string()
      .required('Username is required')
      .matches(/^[a-zA-Z0-9_.-]+$/, 'Special Characters Are Not Allowed'),
    clinicPlanId: Yup.string().required('Clinic plan required'),
    password: Yup.string().required('Password is required'),
    expiryDate: Yup.date()
      .required('Expiry Date is required')
      .min(currentDate, 'Date cannot be in the past')
      .max(maxDate, 'Date cannot be more than 1 year in future')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fields,
    validationSchema: ClinicSchema,
    onSubmit: async (values, actions) => {
      try {
        let result = '';
        if (id) {
          result = await updateClinic(values, id);
          if (result.data.statusCode === 200) {
            sessionStorage.setItem('alertMsg', t('updated'));
          }
        } else {
          result = await createClinic(values);
          if (result.data.statusCode === 200) {
            sessionStorage.setItem('alertMsg', t('created'));
          }
        }
        if (result.data.statusCode === 200) {
          if (id) {
            sessionStorage.setItem('alertMsg', t('updated'));
          } else {
            sessionStorage.setItem('alertMsg', t('created'));
          }
          navigate('/clinics', { replace: true });
        }
      } catch (error) {
        if (error.statusCode === 401) {
          localStorage.clear();
          sessionStorage.setItem('alertMsg', t('timeout'));
          navigate('/login');
        }
        actions.setSubmitting(false);
        setErrors(error.message);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Container fixed maxWidth="md">
      <div className="pageHeading">
        <span style={{ position: 'absolute', left: '-50px' }}>
          <IconButton
            color="primary"
            onClick={() => navigate(-1)}
            aria-label="upload picture"
            component="span"
          >
            <ArrowBackIcon />
          </IconButton>
        </span>
        <h1 className="pageTitle">{id ? t('Update Clinic') : t('Create Clinic')}</h1>
      </div>
      <FormikProvider value={formik}>
        <p style={{ marginBottom: '20px' }}>{error}</p>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mb: '20px' }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="clinicName"
                type="text"
                label={t('Clinic Name')}
                {...getFieldProps('clinicName')}
                error={Boolean(touched.clinicName && errors.clinicName)}
                helperText={touched.clinicName && t(errors.clinicName)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="clinicEmail"
                type="email"
                label={t('Email')}
                {...getFieldProps('clinicEmail')}
                error={Boolean(touched.clinicEmail && errors.clinicEmail)}
                helperText={touched.clinicEmail && t(errors.clinicEmail)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="userName"
                type="text"
                label={t('Username')}
                {...getFieldProps('userName')}
                error={Boolean(touched.userName && errors.userName)}
                helperText={touched.userName && t(errors.userName)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label={t('Password')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && t(errors.password)}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <DatePickerField name="date" /> */}
              <TextField
                fullWidth
                autoComplete="expiryDate"
                type="date"
                formate="DD-MM-YYYY"
                {...getFieldProps('expiryDate')}
                // label={t('Expiry Date')}
                error={Boolean(touched.expiryDate && errors.expiryDate)}
                helperText={touched.expiryDate && t(errors.expiryDate)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                autoComplete="clinicPlanId"
                type="text"
                label={t('Plan')}
                {...getFieldProps('clinicPlanId')}
                error={Boolean(touched.clinicPlanId && errors.clinicPlanId)}
                helperText={touched.clinicPlanId && t(errors.clinicPlanId)}
              >
                {plan.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.planName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {id ? t('Update') : t('Create')}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Container>
  );
};

export default withTranslation()(ClinicForm);
