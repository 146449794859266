import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import PeopleIcon from '@mui/icons-material/People';
import lockFill from '@iconify/icons-eva/lock-fill';
import { withTranslation } from 'react-i18next';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import account from '../../_mocks_/account';

// ----------------------------------------------------------------------
import { getUserRole } from '../../config/api';
import configSidebar from './SidebarConfig';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={50} height={22} />;

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

function DashboardSidebar({ isOpenSidebar, onCloseSidebar, t }) {
  const { pathname } = useLocation();
  const [sidebarConfig, setSidebarConfig] = useState(configSidebar);
  const [userData, setUserData] = useState(account);
  const navigate = useNavigate();
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    let sidebar = [];
    if (getUserRole() === 'super_admin') {
      sidebar = [
        {
          title: 'Clinics',
          path: '/clinics',
          icon: getIcon('maki:hospital')
        },
        {
          title: 'Problems',
          path: '/problems',
          icon: getIcon(pieChart2Fill)
        },
        {
          title: 'Contact Us',
          path: '/contactus',
          icon: getIcon('carbon:phone-filled')
        }
        // {
        //   title: 'logout',
        //   path: '/logout',
        //   icon: getIcon('ri:logout-box-line')
        // }
      ];
    } else if (getUserRole() === 'clinic_admin') {
      sidebar = [
        {
          title: 'Patients',
          path: '/patients',
          icon: getIcon('eva:people-fill')
        },
        {
          title: 'Staff',
          path: '/staffs',
          icon: getIcon('clarity:shield-check-solid')
        },
        {
          title: 'Contact Us',
          path: '/contactus',
          icon: getIcon('carbon:phone-filled')
          // icon: <Icon icon="carbon:phone-filled" />
        }
        // {
        //   title: 'logout',
        //   path: '/logout',
        //   icon: getIcon('ri:logout-box-line')
        // }
      ];
    } else {
      sidebar = [
        {
          title: 'Patients',
          path: '/patients',
          icon: getIcon('eva:people-fill')
        },
        {
          title: 'Contact Us',
          path: '/contactus',
          icon: getIcon('carbon:phone-filled')
        }
        // {
        //   title: 'logout',
        //   path: '/logout',
        //   icon: getIcon('ri:logout-box-line')
        // }
      ];
    }

    setSidebarConfig(sidebar);

    let userDetails = localStorage.getItem('userInfo');
    if (userDetails) {
      userDetails = JSON.parse(userDetails);
      setUserData(userDetails);
    }
    const userInfo = {
      displayName: userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : '-',
      email: 'demo@minimals.cc',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg'
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const user = localStorage.getItem('user_role');
  const ListNavigate = () => {
    if (user) {
      if (getUserRole() === 'super_admin') {
        navigate('/clinics');
      } else if (getUserRole() === 'clinic_admin') {
        navigate('/patients');
      } else {
        navigate('/patients');
      }
    }
  };
  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3, padding: '15px 30%' }}>
        <Box style={{ cursor: 'pointer' }} onClick={ListNavigate} sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
        <h3 style={{ textAlign: 'center' }}>
          <span
            style={{
              background: 'linear-gradient(90deg, rgba(171,48,177,1) 0%, rgba(91,114,180,1) 70%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            DentalCam
          </span>
        </h3>
      </Box>

      <Link
        underline="none"
        component={RouterLink}
        to="/profile"
        style={{ wordBreak: 'break-all' }}
      >
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <AccountStyle>
            {/* <Avatar src={userData.photoURL} alt="photoURL" /> */}
            <RouterLink to="/profile">
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'text.primary', textTransform: 'capitalize' }}
                >
                  {userData.clinicName || `${userData.firstName} ${userData.lastName}`}
                </Typography>
                {userData.clinicEmail ? (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    <p style={{ textDecoration: 'none', color: '#637381' }}>
                      {userData.clinicEmail}
                    </p>
                  </Typography>
                ) : null}
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  <u style={{ textDecoration: 'none', color: '#637381' }}>{t('View Profile')}</u>
                </Typography>
              </Box>
            </RouterLink>
          </AccountStyle>
        </Box>
      </Link>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

export default withTranslation()(DashboardSidebar);
