const Footer = () => (
  <div className="footer">
    <p>
      Copyright © 2022{' '}
      <a href="https://dentalcam.app/" target="_blank" rel="noreferrer">
        DentalCam
      </a>
    </p>
    {/* <p>
      Powered By{' '}
      <a href="https://www.spectusinfotech.com/" target="_blank" rel="noreferrer">
        Spectus Infotech
      </a>
    </p> */}
  </div>
);

export default Footer;
